import * as React from 'react';
import styled from 'styled-components';

import { DashboardTabs as DashboardTabsEnum } from '../../models';
import { useAppDispatch, useAppState, selectDashboardTab } from '../../context';
import ErrorsTab from './ErrorsTab';
import ServicesTab from './ServicesTab';
import TabHeader from './TabHeader';

const TabsRow = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;
    justify-content: start;
`;

const Content = styled.div`
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    min-height: 350px;
    padding: 30px 20px;
`;

const DashboardTabs: React.FC = () => {
    const dispatch = useAppDispatch();
    const {
        dashboardPage: { activeTab, recordErrors },
    } = useAppState();

    const selectErrorsTab = () => {
        dispatch(selectDashboardTab(DashboardTabsEnum.ERRORS));
    };

    const selectServicesTab = () => {
        dispatch(selectDashboardTab(DashboardTabsEnum.SERVICES));
    };

    return (
        <div>
            <TabsRow>
                <TabHeader
                    isActive={activeTab === DashboardTabsEnum.ERRORS}
                    onClick={selectErrorsTab}
                    text={`${recordErrors.length} Record Errors`}
                />
                <TabHeader
                    isActive={activeTab === DashboardTabsEnum.SERVICES}
                    onClick={selectServicesTab}
                    text="Services & API Status"
                />
            </TabsRow>
            <Content>
                {activeTab === DashboardTabsEnum.ERRORS ? <ErrorsTab /> : null}
                {activeTab === DashboardTabsEnum.SERVICES ? (
                    <ServicesTab />
                ) : null}
            </Content>
        </div>
    );
};

export default DashboardTabs;
