import * as React from 'react';
import styled from 'styled-components';

const OuterCircle = styled.div`
    align-items: center;
    background-color: var(--french-blue);
    border-radius: 9px;
    display: flex;
    height: 18px;
    justify-content: center;
    width: 18px;
`;

const InnerCircle = styled.div`
    background-color: white;
    border-radius: 3px;
    height: 6px;
    width: 6px;
`;

export const ActiveTabCircle: React.FC = () => {
    return (
        <OuterCircle>
            <InnerCircle />
        </OuterCircle>
    );
};
