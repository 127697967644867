import { SpinnerState as State } from './State';
import { SpinnerAction as Action, SpinnerActionType as ActionType } from './Action';

const checkExhaustive = (_: never): void => {};

export const spinnerReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionType.ENABLE_SPINNER: {
            return {
                ...state,
                spinnerEnabled: true,
            };
        }
        case ActionType.DISABLE_SPINNER: {
            return {
                ...state,
                spinnerEnabled: false,
            };
        }
        default:
            checkExhaustive(action);
            return state;
    }
};
