import { AppAction } from '../AppAction';
import { ModalActionType as ActionType } from './Action';
import { ModalType } from '../../models';
import { DocumentError } from '../../models';

export const showDiscardModal = (recordId: string, recordType: string, recordStatus: string, recordsLength?: number): AppAction => ({
    type: ActionType.SHOW_DISCARD_MODAL,
    modalType: ModalType.DISCARD,
    recordId,
    recordType,
    recordStatus,
    recordsLength,
});

export const showRestartModal = (recordId: string, recordType: string, recordStatus: string, recordsLength?: number): AppAction => ({
    type: ActionType.SHOW_DISCARD_MODAL,
    modalType: ModalType.RESTART,
    recordId,
    recordType,
    recordStatus,
    recordsLength,
});

export const showErrorModal = (message?: any): AppAction => ({
    type: ActionType.SHOW_ERROR_MODAL,
    modalType: ModalType.ERROR,
    data: message,
});

export const showErrorDetailModal = (errors: DocumentError[]): AppAction => ({
    type: ActionType.SHOW_ERROR_DETAIL_MODAL,
    modalType: ModalType.ERROR_DETAILS,
    data: errors,
});

export const closeModal = (): AppAction => ({
    type: ActionType.CLOSE_MODAL,
});

export const toggleConfirmDiscard = (): AppAction => ({
    type: ActionType.TOGGLE_CONFIRM_DISCARD,
});

export const toggleConfirmRestart = (): AppAction => ({
    type: ActionType.TOGGLE_CONFIRM_RESTART,
});

export const toggleConfirmDiscardAll = (): AppAction => ({
    type: ActionType.TOGGLE_CONFIRM_DISCARD_ALL,
});

export const toggleCanceledDiscardAll = (): AppAction => ({
    type: ActionType.TOGGLE_CANCELED_DISCARD_ALL,
});
