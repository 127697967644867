import * as React from 'react';
import styled from 'styled-components';
import { Moment } from 'moment';

import { useAppState, useAppDispatch, toggleFlyoutOpen, filterByRecordTypeImmediately, filterByRecordStatusImmediately, setFiltersAreApplied } from '../../context';
import { RecordTypes, RecordStatus } from '../../models';
import { BadgeColor, FilterBadge } from './FilterBadge';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const Wrapper = styled.div`
    display: grid;
    grid-gap: 6px;
`;

const Label = styled.div`
    font-size: 13px;
`;

const BadgeRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
    margin-bottom: -3px;
    min-height: 25px;
`;

const FORMAT = 'MM/DD/YY hA';

const formatDateRange = (start: Moment, end: Moment) => {
    return `${start.format(FORMAT)} - ${end.format(FORMAT)}`;
};

export const FilterBadges: React.FC = () => {
    const dispatch = useAppDispatch();
    const { globalFilters } = useAppState();

    const onClickRecordType = (type: RecordTypes) => {
        dispatch(setFiltersAreApplied(true));
        dispatch(filterByRecordTypeImmediately(type));
    };
    const onClickRecordStatus = (status: RecordStatus) => {
        dispatch(setFiltersAreApplied(true));
        dispatch(filterByRecordStatusImmediately(status));
    };
    const openFlyout = () => {
        dispatch(toggleFlyoutOpen());
    };

    const renderUnappliedFiltersBadge = () => {
        return (
            globalFilters.filtersAreChangedButUnapplied && (
                <FilterBadge
                    onClick={() => {
                        openFlyout();
                    }}
                    showCloseButton={false}
                    label="You have unapplied filters"
                    icon={faExclamationCircle}
                    bgColor={BadgeColor.Red}
                />
            )
        );
    };

    return (
        <Wrapper>
            <Label>Global Filters</Label>
            <BadgeRow>
                <FilterBadge
                    label={formatDateRange(globalFilters.currentFilters.dateRange.start, globalFilters.currentFilters.dateRange.end)}
                    onClick={() => {
                        /* no-op */
                    }}
                    showCloseButton={false}
                />
                {globalFilters.currentFilters.recordTypes.map((type) => {
                    return <FilterBadge key={type} label={type} onClick={() => onClickRecordType(type)} showCloseButton={true} />;
                })}
                {globalFilters.currentFilters.recordStatus.map((status) => {
                    return <FilterBadge key={status} label={status} onClick={() => onClickRecordStatus(status)} showCloseButton={true} />;
                })}
                {renderUnappliedFiltersBadge()}
            </BadgeRow>
        </Wrapper>
    );
};
