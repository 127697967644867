import { AdminPageState as State } from './State';
import { AdminPageAction as Action, AdminPageActionType as ActionType } from './Action';

const checkExhaustive = (_: never): void => {};

export const adminPageReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionType.SET_INGESTOR_V2_STATUS: {
            return {
                ...state,
                ingestorV2Status: [...action.ingestorV2Status],
            };
        }
        case ActionType.GET_INGESTOR_STATUS: {
            return {
                ...state,
            };
        }
        default:
            checkExhaustive(action);
            return state;
    }
};
