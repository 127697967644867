import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, IconDefinition } from '@fortawesome/free-solid-svg-icons';

export enum BadgeColor {
    Purple,
    Red,
}

interface BadgeStyles {
    readonly showCloseButton: boolean;
    readonly bgColor: BadgeColor;
}

export const Badge = styled.div<BadgeStyles>`
    align-items: center;
    border-radius: 10px;
    color: white;
    cursor: ${({ showCloseButton }) => (showCloseButton ? 'pointer' : 'auto')};
    display: inline-grid;
    font-size: 10px;
    grid-auto-flow: column;
    grid-column-gap: 10px;
    height: 19px;
    line-height: 19px;
    margin: 3px 5px;
    padding: 0 12px;
    ${({ bgColor }) => {
        if (bgColor === BadgeColor.Red) {
            return 'background-color: var(--rose-madder)';
        }
        return 'background-color: var(--rebecca-purple)';
    }}
`;

interface Props {
    label: string;
    onClick: () => void;
    showCloseButton: boolean;
    icon?: IconDefinition;
    bgColor?: BadgeColor;
}

export const FilterBadge: React.FC<Props> = ({ label, onClick, showCloseButton, icon, bgColor }) => {
    return (
        <Badge onClick={onClick} showCloseButton={showCloseButton} bgColor={bgColor ?? BadgeColor.Purple}>
            {icon && <FontAwesomeIcon icon={icon} size="xs" />}
            {label}
            {showCloseButton ? <FontAwesomeIcon icon={faTimes} size="xs" /> : null}
        </Badge>
    );
};
