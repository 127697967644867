import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    font-size: 2em;
`;

const LoginLink = styled.a`
    margin-top: 30px;
    font-size: 0.6em;
`;
interface Props {
    linkText?: string;
}
export const NotAuthorized: React.FC<Props> = ({ linkText }) => {
    return (
        <Wrapper>
            Not Authorized
            <LoginLink href="/">{linkText ?? 'Retry Login'}</LoginLink>
        </Wrapper>
    );
};
