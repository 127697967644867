import { DateFilterMode } from '../DateFilterMode';
import { FilterQueryParams } from '../FilterQueryParams';
import { RecordStatus } from '../RecordStatus';
import { RecordTypes } from '../RecordTypes';

export interface RequestFilters {
    statuses: FilterStatus[];
    recordTypes: FilterTypes[];
    dateFilterMode: FilterDateFilterMode;
}

export enum FilterStatus {
    Complete = 'Complete',
    Discarded = 'Discarded',
    Error = 'Error',
    Pending = 'Pending',
    Processing = 'Processing',
}

const filterStatusMap: {
    [key in RecordStatus]: FilterStatus;
} = {
    [RecordStatus.Complete]: FilterStatus.Complete,
    [RecordStatus.Discarded]: FilterStatus.Discarded,
    [RecordStatus.Error]: FilterStatus.Error,
    [RecordStatus.Pending]: FilterStatus.Pending,
    [RecordStatus.Processing]: FilterStatus.Processing,
};

export enum FilterTypes {
    EmailOnly = 'EmailOnly',
    Order = 'Order',
    Quote = 'Quote',
    Lead = 'Lead',
    OptOut = 'OptOut',
    Subscription = 'Subscription',
    CancelOrder = 'CancelOrder',
    Membership = 'Membership',
}

const filterTypesMap: {
    [key in RecordTypes]: FilterTypes;
} = {
    [RecordTypes.EmailOnly]: FilterTypes.EmailOnly,
    [RecordTypes.Lead]: FilterTypes.Lead,
    [RecordTypes.Order]: FilterTypes.Order,
    [RecordTypes.Quote]: FilterTypes.Quote,
    [RecordTypes.OptOut]: FilterTypes.OptOut,
    [RecordTypes.Subscription]: FilterTypes.Subscription,
    [RecordTypes.CancelOrder]: FilterTypes.CancelOrder,
    [RecordTypes.Membership]: FilterTypes.Membership,
};

export enum FilterDateFilterMode {
    Intake = 'IngestDate',
    LastTransition = 'LastTransition',
}

export const mapFilterQueryParamsToRequestFilters = (fqp: FilterQueryParams): RequestFilters => ({
    statuses: fqp.statuses.map((st) => filterStatusMap[st]),
    recordTypes: fqp.recordTypes.map((rt) => filterTypesMap[rt]),
    dateFilterMode: fqp.dateFilterMode === DateFilterMode.IngestDate ? FilterDateFilterMode.Intake : FilterDateFilterMode.LastTransition,
});
