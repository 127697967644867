import { useReactOidc } from '@axa-fr/react-oidc-context';
import React, { ComponentType, PropsWithChildren } from 'react';
import { environment } from '../../environment';
import { NotAuthorized } from './NotAuthorized';

export const pciSecure = (WrappedComponent: ComponentType) => (props: PropsWithChildren<any>) => {
    const { oidcUser } = useReactOidc();
    let allowedGroups = [environment.adminGroupId, environment.writeGroupId, environment.devGroupId];
    let isAllowed = allowedGroups.find((val, index) => oidcUser.profile['custom:groups'].includes(val));
    if (!isAllowed) {
        return <NotAuthorized linkText="Go back to dashboard"></NotAuthorized>;
    }

    return <WrappedComponent {...props} />;
};
