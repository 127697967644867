import RequestBuilder from './RequestBuilder';
import { RecordDetailRequest } from '../models/request';
import { FilterQueryParams } from '../models';
import { mapFilterQueryParamsToRequestFilters } from '../models/request';
import { environment } from '../environment';
import { DashboardResponse, LiveStatResponse, RecordDetailResponse } from '../models/response';
import { DecryptPaymentInfoResponse } from '../models/response/DecryptPaymentInfoResponse';
import { DecryptPaymentInfoRequest } from '../models/request/DecryptPaymentInfoRequest';
import { SearchResponse } from '../models/response/SearchResponse';

const API_BASE_URL = environment.baseUrl;

const buildFilterQueryString = (params: FilterQueryParams) => {
    const filters = mapFilterQueryParamsToRequestFilters(params);

    const sp = new URLSearchParams();
    filters.recordTypes.forEach((rt) => sp.append('recordTypes', rt));
    filters.statuses.forEach((st) => sp.append('statuses', st));
    sp.append('dateFilterMode', filters.dateFilterMode);
    return sp;
};

const getSearchRecords = async (params: FilterQueryParams, searchFor: string): Promise<SearchResponse> => {
    const { start, end } = params;
    const sp = buildFilterQueryString(params);
    sp.set('search', searchFor ? searchFor : '');
    return RequestBuilder.getInstance().get(`${API_BASE_URL}/api/documents/${start.toISOString()}/${end.toISOString()}?${sp}`);
};

const getDashboardData = async (params: FilterQueryParams): Promise<DashboardResponse> => {
    const { start, end } = params;
    const sp = buildFilterQueryString(params);
    return RequestBuilder.getInstance().get(`${API_BASE_URL}/api/documents/dashboard/${start.toISOString()}/${end.toISOString()}?${sp}`);
};

const getRecordDetail = async (id: string): Promise<RecordDetailResponse> => {
    return RequestBuilder.getInstance().get(`${API_BASE_URL}/api/documents/${id}`);
};

const getDecryptedCCNumber = async (request: DecryptPaymentInfoRequest): Promise<DecryptPaymentInfoResponse> => {
    return RequestBuilder.getInstance().put(`${API_BASE_URL}/api/decrypt/token`, request);
};

const getDecryptedAccountNumber = async (request: DecryptPaymentInfoRequest): Promise<DecryptPaymentInfoResponse> => {
    return RequestBuilder.getInstance().put(`${API_BASE_URL}/api/decrypt/account`, request);
};

const putRecordDetail = async (id: string, request: RecordDetailRequest) => {
    return RequestBuilder.getInstance().put(`${API_BASE_URL}/api/documents/${id}`, request);
};

const getLiveStats = async (): Promise<LiveStatResponse[]> => {
    return RequestBuilder.getInstance().get(`${API_BASE_URL}/api/documents/stats`);
};

const putDiscard = (documentId: string, recordType: string, status: string) => {
    return RequestBuilder.getInstance()
        .put(`${API_BASE_URL}/api/documents/${documentId}/discard?recordType=${recordType}&status=${status}`)
        .then(() => 'success');
};

const putRetry = (documentId: string, recordType: string, status: string) => {
    return RequestBuilder.getInstance()
        .put(`${API_BASE_URL}/api/documents/${documentId}/retry?recordType=${recordType}&status=${status}`)
        .then(() => 'success');
};

const putRestart = (documentId: string, recordType: string, status: string) => {
    return RequestBuilder.getInstance()
        .put(`${API_BASE_URL}/api/documents/${documentId}/restart?recordType=${recordType}&status=${status}`)
        .then(() => 'success');
};

export default {
    getDashboardData,
    getLiveStats,
    getRecordDetail,
    getSearchRecords,
    getDecryptedCCNumber,
    getDecryptedAccountNumber,
    putRecordDetail,
    putDiscard,
    putRetry,
    putRestart,
};
