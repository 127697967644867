import * as React from 'react';
import styled from 'styled-components';

import { useAppState } from '../../context';
import { RecordStatus } from '../../models';
import DiscardedBanner from './DiscardedBanner';
import ErrorBanner from './ErrorBanner';

const Wrapper = styled.div`
    box-sizing: border-box;
    padding-left: 115px;
`;

const FormBanners: React.FC = () => {
    const {
        recordDetailPage: { recordDetail },
    } = useAppState();

    const hasError = recordDetail.Errors.length > 0;
    const isDiscarded = recordDetail.Status === RecordStatus.Discarded;

    if (!isDiscarded && !hasError) {
        return null;
    }

    return (
        <Wrapper>
            {isDiscarded ? <DiscardedBanner /> : null}
            {hasError ? <ErrorBanner errors={recordDetail.Errors} isOnTop={!isDiscarded} /> : null}
        </Wrapper>
    );
};

export default FormBanners;
