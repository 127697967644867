import { disableSpinner, enableSpinner, showErrorModal, useAppDispatch } from '../../context';
import { setIngestorV2Status } from '../../context/admin-page/Dispatch';
import { IngestorV2State } from '../../models/IngestorState';
import AdminSvc from '../../services/AdminSvc';

export const useFetchIngestorStatus = () => {
    const dispatch = useAppDispatch();

    const fetchIngestorStatus = () => {
        const fetchIngestorStatusAsync = async () => {
            dispatch(enableSpinner());
            const result = await AdminSvc.getIngestorV2Status().catch((e) => {
                console.error('Error fetching ingestor status', e);
                dispatch(showErrorModal());
            });
            if (result) {
                const states = result.map((d) => {
                    return {
                        OriginalStatus: d,
                        Limit: d.recordsPerExecution,
                        Enabled: d.enabled,
                        HasPendingChange: false,
                    } as IngestorV2State;
                });
                dispatch(setIngestorV2Status(states));
            }

            dispatch(disableSpinner());
        };

        fetchIngestorStatusAsync();
    };

    return {
        fetchIngestorStatus,
    };
};
