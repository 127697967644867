import * as React from 'react';
import styled from 'styled-components';
import JSONPretty from 'react-json-pretty';
import { toggleShowOriginalRecord, useAppDispatch, useAppState } from '../../context';
import { RecordStatus, RecordTypes } from '../../models';
import DetailFormFields from './DetailFormFields';
import OrderLines from './OrderLines';
import OrderPayments from './OrderPayments';
import OriginalRecordTab from './OriginalRecordTab';
import { IngestorVersion } from '../../models/IngestorVersion';

interface OrigRecordStyles {
    readonly showOriginalRecord: boolean;
}

const jsonTheme = {
    main: 'padding:10px;margin:0;border-radius:5px;line-height:1.3;color:#2733a1;background:#eeeeee;overflow:auto;',
    error: 'line-height:1.3;color:#2733a1;background:#eeeeee;overflow:auto;',
    key: 'color:#f92672;',
    string: 'color:#ce740c;',
    value: 'color:#77a914;',
    boolean: 'color:#ac81fe;',
};

const Wrapper = styled.div<OrigRecordStyles>`
    display: grid;
    grid-template-columns: ${({ showOriginalRecord }) => (showOriginalRecord ? '40px 500px 1fr' : '40px 1fr')};
    padding-left: ${({ showOriginalRecord }) => (showOriginalRecord ? '40px' : '75px')};
    transition: padding-left 0.3s;
`;

interface DetailsStyles {
    readonly isOnTop: boolean;
}

const Details = styled.div<DetailsStyles>`
    background-color: white;
    border-bottom-left-radius: 10px;
    ${({ isOnTop }) => (isOnTop ? 'border: 10px 10px 0 0;' : '')}

    min-height: 200px;
    padding: 30px 50px;
`;
const DetailRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 115px;
    grid-row-gap: 25px;
    justify-content: start;
    margin-bottom: 50px;
`;

const OrigRecordCol = styled.div`
    background-color: white;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    margin-bottom: 20px;
    padding: 30px;
    display: grid;
    overflow-x: auto;
    z-index: 1;
    &::-webkit-scrollbar {
        width: 0.75em;
        height: 0.75em;
        background-color: transparent;
    }

    &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--sortof-gray);
        border-radius: 10px;
    }
`;

const EditRecordCol = styled.div`
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.25);
    padding: 30px 50px;
    min-height: 200px;
    z-index: 2;
`;

const DetailForm: React.FC = () => {
    const {
        recordDetailPage: { recordDetail, showOriginalRecord },
    } = useAppState();
    const dispatch = useAppDispatch();

    const onToggleOriginal = () => {
        dispatch(toggleShowOriginalRecord());
    };
    const renderOriginalDoc = () => {
        if (recordDetail.IngestorVersion === IngestorVersion.Version_2_0) {
            return <JSONPretty theme={jsonTheme} data={recordDetail.OriginalJson}></JSONPretty>;
        }
        return <DetailFormFields document={JSON.parse(recordDetail.OriginalJson)} readOnly={true} />;
    };

    const canBeEdited = recordDetail.Status === RecordStatus.Error;
    const hasError = recordDetail.Errors.length > 0;
    const isDiscarded = recordDetail.Status === RecordStatus.Discarded;

    return (
        <Wrapper showOriginalRecord={showOriginalRecord}>
            <OriginalRecordTab isActive={showOriginalRecord} onClick={onToggleOriginal} />
            {!showOriginalRecord ? (
                <Details isOnTop={!isDiscarded && !hasError}>
                    <DetailRow>
                        <DetailFormFields document={recordDetail} readOnly={!canBeEdited} />
                    </DetailRow>
                    {(recordDetail.RecordType === RecordTypes.Order || recordDetail.RecordType === RecordTypes.Quote) && (
                        <React.Fragment>
                            <OrderLines />
                            <OrderPayments />
                        </React.Fragment>
                    )}
                </Details>
            ) : (
                <React.Fragment>
                    <OrigRecordCol>{recordDetail.OriginalJson && renderOriginalDoc()}</OrigRecordCol>
                    <EditRecordCol>
                        <DetailRow>
                            <DetailFormFields document={recordDetail} readOnly={!canBeEdited} />
                        </DetailRow>
                        {(recordDetail.RecordType === RecordTypes.Order || recordDetail.RecordType === RecordTypes.Quote) && (
                            <React.Fragment>
                                <OrderLines />
                                <OrderPayments />
                            </React.Fragment>
                        )}
                    </EditRecordCol>
                </React.Fragment>
            )}
        </Wrapper>
    );
};

export default DetailForm;
