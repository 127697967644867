import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    align-items: center;
    background-color: white;
    border-radius: 20px;
    color: var(--french-blue);
    cursor: pointer;
    display: flex;
    font-family: var(--open-sans-semibold-font);
    font-size: 16px;
    height: 30px;
    justify-content: center;
    padding: 0 20px;
`;

interface Props {
    onClick: () => void;
}

const ErrorDetailButton: React.FC<Props> = ({ onClick }) => {
    return <Wrapper onClick={onClick}>Get Error Details</Wrapper>;
};

export default ErrorDetailButton;
