import * as React from 'react';
import styled from 'styled-components';
import { keyframes } from 'styled-components';
import { useAppState } from '../../context';

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Backer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.5;
    z-index: 1;
`;

const ellipsis1 = keyframes`
0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;
const ellipsis3 = keyframes`
0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;
const ellipsis2 = keyframes`
0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
`;

const Spinner = styled.div`
    position: relative;
    width: 80px;
    height: 40px;

    z-index: 2;
    div {
        position: absolute;
        top: 14px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: #fff;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }

    div:nth-child(1) {
        left: 8px;
        animation: ${ellipsis1} 0.6s infinite;
    }
    div:nth-child(2) {
        left: 8px;
        animation: ${ellipsis2} 0.6s infinite;
    }
    div:nth-child(3) {
        left: 32px;
        animation: ${ellipsis2} 0.6s infinite;
    }
    div:nth-child(4) {
        left: 56px;
        animation: ${ellipsis3} 0.6s infinite;
    }
`;
const Message = styled.div`
    color: white;
    z-index: 2;
`;

export const FullPageSpinner: React.FC = () => {
    const {
        spinner: { spinnerEnabled },
    } = useAppState();

    const renderSpinner = () => {
        return (
            <Wrapper>
                <Backer></Backer>
                <Message>Loading</Message>
                <Spinner>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </Spinner>
            </Wrapper>
        );
    };

    return spinnerEnabled ? renderSpinner() : <></>;
};
