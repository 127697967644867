import { RecordStatus, RecordTypes } from '../../models';

export enum GlobalFilterActionType {
    FILTER_BY_RECORD_TYPE = 'FILTER_BY_RECORD_TYPE',
    FILTER_BY_RECORD_TYPE_IMMEDIATE = 'FILTER_BY_RECORD_TYPE_IMMEDIATE',
    TOGGLE_FILTER_BY_RECORD_TYPE_ALL = 'TOGGLE_FILTER_BY_RECORD_TYPE_ALL',
    TOGGLE_FILTER_BY_STATUS = 'TOGGLE_FILTER_BY_STATUS',
    TOGGLE_FILTER_BY_STATUS_IMMEDIATE = 'TOGGLE_FILTER_BY_STATUS_IMMEDIATE',
    TOGGLE_FILTER_BY_STATUS_ALL = 'TOGGLE_FILTER_BY_STATUS_ALL',
    SET_START_DATE = 'SET_START_DATE',
    SET_END_DATE = 'SET_END_DATE',
    SET_DATE_RANGE = 'SET_DATE_RANGE',
    TOGGLE_DATE_FILTER_MODE = 'TOGGLE_DATE_FILTER_MODE',
    RESET_FILTERS_TO_DEFAULT = 'RESET_FILTERS_TO_DEFAULT',
    APPLY_FILTERS = 'APPLY_FILTERS',
    DISCARD_PENDING_FILTERS = 'DISCARD_PENDING_FILTERS',
    FILTERS_ARE_APPLIED = 'FILTERS_ARE_APPLIED',
}

export type GlobalFilterAction =
    | {
          type: GlobalFilterActionType.APPLY_FILTERS;
      }
    | {
          type: GlobalFilterActionType.DISCARD_PENDING_FILTERS;
      }
    | {
          type: GlobalFilterActionType.FILTER_BY_RECORD_TYPE;
          recordType: RecordTypes;
      }
    | {
          type: GlobalFilterActionType.FILTER_BY_RECORD_TYPE_IMMEDIATE;
          recordType: RecordTypes;
      }
    | { type: GlobalFilterActionType.TOGGLE_FILTER_BY_RECORD_TYPE_ALL }
    | {
          type: GlobalFilterActionType.TOGGLE_FILTER_BY_STATUS;
          status: RecordStatus;
      }
    | {
          type: GlobalFilterActionType.TOGGLE_FILTER_BY_STATUS_IMMEDIATE;
          status: RecordStatus;
      }
    | { type: GlobalFilterActionType.TOGGLE_FILTER_BY_STATUS_ALL }
    | {
          type: GlobalFilterActionType.SET_START_DATE;
          year: number;
          month: number;
          day: number;
          hour: number;
      }
    | {
          type: GlobalFilterActionType.SET_END_DATE;
          year: number;
          month: number;
          day: number;
          hour: number;
          dateDiff?: number;
      }
    | {
          type: GlobalFilterActionType.SET_DATE_RANGE;
          startYear: number;
          startMonth: number;
          startDay: number;
          startHour: number;
          endYear: number;
          endMonth: number;
          endDay: number;
          endHour: number;
          dateDiff?: number;
      }
    | { type: GlobalFilterActionType.TOGGLE_DATE_FILTER_MODE }
    | {
          type: GlobalFilterActionType.RESET_FILTERS_TO_DEFAULT;
      }
    | {   type: GlobalFilterActionType.FILTERS_ARE_APPLIED; 
          filtersAreApplied: boolean;    
      };
