import { BipsliDocument } from '../../models';
import { AppAction } from '../AppAction';
import { RecordDetailPageActionType } from './Action';

export const setRecordDetail = (record: BipsliDocument): AppAction => ({
    type: RecordDetailPageActionType.SET_RECORD,
    record,
});

export const setEditedRecord = (record: Partial<BipsliDocument>): AppAction => ({
    type: RecordDetailPageActionType.SET_EDIT_RECORD,
    record,
});

export const revertRecordDetailField = (field: keyof BipsliDocument): AppAction => ({
    type: RecordDetailPageActionType.REVERT_FIELD,
    field,
});

export const toggleShowOriginalRecord = (): AppAction => ({
    type: RecordDetailPageActionType.TOGGLE_ORIG_RECORD,
});

export const toggleEditingField = (field: keyof BipsliDocument): AppAction => ({
    type: RecordDetailPageActionType.TOGGLE_EDITING_FIELD,
    field,
});

export const clearEditingFields = (): AppAction => ({
    type: RecordDetailPageActionType.CLEAR_EDITING_FIELDS,
});

export const clearRecords = (): AppAction => ({
    type: RecordDetailPageActionType.CLEAR_RECORDS,
});
