import * as React from 'react';
import { disableSpinner, enableSpinner, showErrorModal, useAppDispatch, useAppState } from '../../context';
import { IngestorV2State } from '../../models/IngestorState';
import { setIngestorV2Status } from '../../context/admin-page/Dispatch';
import { BaseButton } from '../common/BaseButton';
import { ButtonType } from '../../models/ButtonTypes';
import AdminSvc from '../../services/AdminSvc';
import { UpdateIngestorV2Request } from '../../models/request/UpdateIngestorRequest';
import { Blurb, ButtonGroup, Table, LimitInputBox, Widget, Title, TableHeader, HeaderBorder, TableCell } from './WidgetStyles';
import { StateToggle } from '../common/StateToggle';

const IngestStatusV2Widget: React.FC = () => {
    const {
        adminPage: { ingestorV2Status },
    } = useAppState();
    const dispatch = useAppDispatch();

    const updateState = (stat: IngestorV2State, propName: string, value: any) => {
        let newStatus = [...ingestorV2Status];
        let thisStat = newStatus.find((s) => s.OriginalStatus.recordType === stat.OriginalStatus.recordType);
        thisStat[propName] = value;
        if (thisStat.Enabled !== stat.OriginalStatus.enabled || thisStat.Limit !== stat.OriginalStatus.recordsPerExecution) {
            thisStat.HasPendingChange = true;
        } else {
            thisStat.HasPendingChange = false;
        }
        dispatch(setIngestorV2Status(newStatus));
    };

    const renderRow = (stat: IngestorV2State) => {
        let onStatusClick = () => {
            updateState(stat, 'Enabled', !stat.Enabled);
        };

        let onLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            updateState(stat, 'Limit', parseInt(event.target.value));
        };

        let discardChanges = () => {
            let newStatus = [...ingestorV2Status];
            let thisStat = newStatus.find((s) => s.OriginalStatus.recordType === stat.OriginalStatus.recordType);
            thisStat.Enabled = thisStat.OriginalStatus.enabled;
            thisStat.Limit = thisStat.OriginalStatus.recordsPerExecution;
            thisStat.HasPendingChange = false;
            dispatch(setIngestorV2Status(newStatus));
        };

        let applyChanges = async () => {
            dispatch(enableSpinner());
            let newStatus = [...ingestorV2Status];
            let thisStat = newStatus.find((s) => s.OriginalStatus.recordType === stat.OriginalStatus.recordType);

            const req = {
                RecordType: thisStat.OriginalStatus.recordType,
                Enabled: thisStat.Enabled,
                RecordsPerExecution: thisStat.Limit,
            } as UpdateIngestorV2Request;

            await AdminSvc.setIngestorV2Config(req).catch((e) => {
                dispatch(showErrorModal());
            });

            //After
            thisStat.OriginalStatus.enabled = thisStat.Enabled;
            thisStat.OriginalStatus.recordsPerExecution = thisStat.Limit;
            thisStat.HasPendingChange = false;

            dispatch(disableSpinner());

            dispatch(setIngestorV2Status(newStatus));
        };

        let renderButtons = (hasChange: boolean) => {
            if (hasChange) {
                return (
                    <ButtonGroup>
                        <BaseButton label="Apply" onClick={applyChanges} fontSize="14px" />
                        <BaseButton type={ButtonType.NegativeRed} label="Discard" onClick={discardChanges} fontSize="14px" />
                    </ButtonGroup>
                );
            }
            return <></>;
        };

        return (
            <React.Fragment key={stat.OriginalStatus.recordType}>
                <TableCell>{stat.OriginalStatus.recordType}</TableCell>
                <TableCell>
                    <StateToggle active={stat.Enabled} onLabel="ON" offLabel="OFF" onColor="--sheen-green" offColor="--international-orange" onClick={onStatusClick}></StateToggle>
                </TableCell>
                <TableCell>
                    <LimitInputBox value={stat.Limit} onChange={(event) => onLimitChange(event)}></LimitInputBox>
                </TableCell>
                <TableCell>{renderButtons(stat.HasPendingChange)}</TableCell>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <Widget>
                <Title>v2 Ingestors</Title>
                <Blurb></Blurb>
                <Table>
                    <TableHeader>Record Type</TableHeader>
                    <TableHeader>Status</TableHeader>
                    <TableHeader title="Maximum concurrent documents processing">Limit</TableHeader>
                    <TableHeader></TableHeader>
                    <HeaderBorder />
                    {ingestorV2Status.map((stat) => renderRow(stat))}
                </Table>
            </Widget>
        </React.Fragment>
    );
};

export default IngestStatusV2Widget;
