import * as React from 'react';
import styled from 'styled-components';

import { closeModal, useAppDispatch, useAppState } from '../../context';
import { ModalType } from '../../models';
import { ConfirmDiscard, Error } from '../modal-content';
import { ConfirmRestart } from '../modal-content/ConfirmRestart';
import { ErrorDetails } from '../modal-content/ErrorDetails';

interface TransitionStyles {
    readonly lowOpacity: boolean;
}

const Overlay = styled.div<TransitionStyles>`
    align-items: center;
    background: rgba(0, 0, 0, ${({ lowOpacity }) => (lowOpacity ? '.4' : '0')});
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    transition: background 0.25s;
    width: 100%;
    z-index: 10;
`;

const Wrapper = styled.div<TransitionStyles>`
    max-width: 700px;
    max-height: 80%;
    background: white;
    border-radius: 10px;
    opacity: ${({ lowOpacity }) => (lowOpacity ? '1' : '0')};
    overflow: auto;
    transition: opacity 0.25s;
    z-index: 11;

    &::-webkit-scrollbar {
        width: 0.75em;
        height: 0.75em;
        background-color: transparent;
    }

    &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--sortof-gray);
        border-radius: 10px;
    }
`;

export const ModalShell: React.FC = () => {
    const {
        modal: { visible, type, data },
    } = useAppState();
    const dispatch = useAppDispatch();
    const [lowOpacity, setLowOpacity] = React.useState(false);

    // This starts the opacity transition
    const callbackRef = React.useCallback((node) => {
        if (!!node) {
            setTimeout(() => setLowOpacity(true), 0);
        }
    }, []);

    // This resets the opacity transition
    React.useEffect(() => {
        if (!visible) {
            setLowOpacity(false);
        }
    }, [visible]);

    if (!visible) {
        return null;
    }

    const renderContents = () => {
        switch (type) {
            case ModalType.DISCARD:
                return <ConfirmDiscard />;
            case ModalType.RESTART:
                return <ConfirmRestart />;
            case ModalType.ERROR:
                return <Error error={data} />;
            case ModalType.ERROR_DETAILS:
                return <ErrorDetails errors={data} />;
            case ModalType.TILE_SETTINGS:
                return <div>{/* TODO */}</div>;
            default:
                return null;
        }
    };

    const onClose = () => {
        dispatch(closeModal());
    };

    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    return (
        <Overlay onClick={onClose} lowOpacity={lowOpacity}>
            <Wrapper onClick={stopPropagation} lowOpacity={lowOpacity} ref={callbackRef}>
                {renderContents()}
            </Wrapper>
        </Overlay>
    );
};
