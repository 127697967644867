import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { ActiveTabCircle } from '../common';

interface TabStyles {
    readonly isActive: boolean;
}

const Tab = styled.div<TabStyles>`
    background-color: var(${({ isActive }) => (isActive ? '--french-blue' : '--french-blue-hover')});
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    cursor: pointer;
    display: flex;
    height: 172px;
    justify-content: center;
    margin-top: 15px;
    position: relative;
    z-index: 2;
`;

const Label = styled.div`
    bottom: 15px;
    color: white;
    font-family: var(--open-sans-semibold-font);
    font-size: 16px;
    position: absolute;
    transform: rotate(-90deg);
    white-space: nowrap;
    width: 20px;
`;

const IconWrapper = styled.div`
    position: absolute;
    top: 10px;
`;

const CirclesWrapper = styled.div`
    position: absolute;
    right: -9px;
    top: 77px;
`;

interface Props {
    isActive: boolean;
    onClick: () => void;
}

const OriginalRecordTab: React.FC<Props> = ({ isActive, onClick }) => {
    return (
        <Tab isActive={isActive} onClick={onClick}>
            <IconWrapper>
                <FontAwesomeIcon color="white" icon={isActive ? faChevronRight : faChevronLeft} size="xs" />
            </IconWrapper>
            <Label>Original Record</Label>
            {isActive ? (
                <CirclesWrapper>
                    <ActiveTabCircle />
                </CirclesWrapper>
            ) : null}
        </Tab>
    );
};

export default OriginalRecordTab;
