import { RecordStatus, RecordTypes } from '../../models';
import { AppAction } from '../AppAction';
import { GlobalFilterActionType } from './Action';

export const filterByRecordType = (recordType: RecordTypes): AppAction => ({
    type: GlobalFilterActionType.FILTER_BY_RECORD_TYPE,
    recordType,
});

export const filterByRecordTypeImmediately = (recordType: RecordTypes): AppAction => ({
    type: GlobalFilterActionType.FILTER_BY_RECORD_TYPE_IMMEDIATE,
    recordType,
});

export const toggleRecordTypeAll = (): AppAction => ({
    type: GlobalFilterActionType.TOGGLE_FILTER_BY_RECORD_TYPE_ALL,
});

export const filterByRecordStatus = (status: RecordStatus): AppAction => ({
    type: GlobalFilterActionType.TOGGLE_FILTER_BY_STATUS,
    status,
});

export const filterByRecordStatusImmediately = (status: RecordStatus): AppAction => ({
    type: GlobalFilterActionType.TOGGLE_FILTER_BY_STATUS_IMMEDIATE,
    status,
});

export const applyPendingFilters = (): AppAction => ({
    type: GlobalFilterActionType.APPLY_FILTERS,    
});

export const discardPendingFilterChanges = (): AppAction => ({
    type: GlobalFilterActionType.DISCARD_PENDING_FILTERS,
});

export const toggleRecordStatusAll = (): AppAction => ({
    type: GlobalFilterActionType.TOGGLE_FILTER_BY_STATUS_ALL,
});

export const resetFiltersToDefault = (): AppAction => ({
    type: GlobalFilterActionType.RESET_FILTERS_TO_DEFAULT,
});

export const setStartDate = (year: number, month: number, day: number, hour: number): AppAction => ({
    type: GlobalFilterActionType.SET_START_DATE,
    year,
    month,
    day,
    hour,
});

export const setEndDate = (year: number, month: number, day: number, hour: number, dateDiff?: number): AppAction => ({
    type: GlobalFilterActionType.SET_END_DATE,
    year,
    month,
    day,
    hour,
    dateDiff,
});

export const setDateRange = (startYear: number, startMonth: number, startDay: number, startHour: number, endYear: number, endMonth: number, endDay: number, endHour: number, dateDiff?: number): AppAction => ({
    type: GlobalFilterActionType.SET_DATE_RANGE,
    startYear,
    startMonth,
    startDay,
    startHour,
    endYear,
    endMonth,
    endDay,
    endHour,
    dateDiff,
});

export const toggleDateFilterMode = (): AppAction => ({
    type: GlobalFilterActionType.TOGGLE_DATE_FILTER_MODE,
});

export const setFiltersAreApplied = (filtersAreApplied: boolean): AppAction => ({
    type: GlobalFilterActionType.FILTERS_ARE_APPLIED,
    filtersAreApplied,
});
