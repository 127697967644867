import { RecordTypes } from './RecordTypes';
import { LiveStatResponse } from './response';

export interface LiveStat {
    docsPerMinute: number;
    recordType: RecordTypes;
    recordsInProcess: number;
    recordsInError: number;
    recentAvgProcessTime?: number;
    thirtyDayAvgProcessTime?: number;
}

export const mapLiveStatResponse = (resp: LiveStatResponse): LiveStat => ({
    docsPerMinute: resp.docsPerMinute,
    recordType: RecordTypes[resp.recordType],
    recordsInProcess: resp.recordsInProcess,
    recordsInError: resp.recordsInError,
    recentAvgProcessTime: resp.recentAvgProcessTime ?? undefined,
    thirtyDayAvgProcessTime: resp.thirtyDayAvgProcessTime ?? undefined,
});
