import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { sortSearchResults, useAppDispatch, useAppState } from '../../context';
import { useSortedSearchResults } from './Hooks';
import { BaseTable, formatDate, FullRowTableCell, TableCell } from '../common';
import { ColumnData, SearchResultsTableColumn } from '../../models';
import DownloadButton from '../dashboard/DownloadButton';
import { SearchResultDoc } from '../../models/response';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NoResultsLabel = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
`;
const MiddleRow = styled.div`
    display: grid;
    grid-template-columns: 200px 1fr 1fr;
`;
const TotalCount = styled.div`
    font-size: 1.2em;
`;
const MaxCountWarning = styled.div`
    background-color: var(--international-orange);
    border-radius: 15px;
    color: white;
    font-family: var(--open-sans-semibold);
    font-size: 16px;
    height: 30px;
    line-height: 30px;
    padding: 0 20px;
    white-space: nowrap;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 15px;
    grid-auto-flow: column;
    grid-column-gap: 5px;
`;
const Icon = styled(FontAwesomeIcon)`
    position: relative;
    top: -2px;
    margin-right: 5px;
`;

const COLUMN_DATA: ColumnData<SearchResultsTableColumn>[] = [
    { label: 'ID', type: SearchResultsTableColumn.ID, sortable: true },
    {
        label: 'Record Type',
        type: SearchResultsTableColumn.RECORD_TYPE,
        sortable: true,
    },
    {
        label: 'Doc Type',
        type: SearchResultsTableColumn.DOC_TYPE,
        sortable: true,
    },
    { label: 'Status', type: SearchResultsTableColumn.STATUS, sortable: true },
    {
        label: 'Customer ID',
        type: SearchResultsTableColumn.CUSTOMER_ID,
        sortable: true,
    },
    {
        label: 'Web Order Number',
        type: SearchResultsTableColumn.WEB_ORDER_NUMBER,
        sortable: false,
    },
    {
        label: 'First Name',
        type: SearchResultsTableColumn.FIRST_NAME,
        sortable: true,
    },
    {
        label: 'Last Name',
        type: SearchResultsTableColumn.LAST_NAME,
        sortable: true,
    },
    { label: 'City', type: SearchResultsTableColumn.CITY, sortable: true },
    { label: 'State', type: SearchResultsTableColumn.STATE, sortable: true },
    {
        label: 'Ingested Date',
        type: SearchResultsTableColumn.INGESTED_DATE,
        sortable: true,
    },
];

const SearchResultsTable: React.FC = () => {
    const {
        searchPage: { sorting, maxLimitReached },
    } = useAppState();
    const { sortedSearchResults } = useSortedSearchResults();
    const dispatch = useAppDispatch();

    const toggleSort = (sortBy: SearchResultsTableColumn) => {
        dispatch(sortSearchResults(sortBy));
    };

    const renderCountBadge = () => {
        return maxLimitReached ? (
            <MaxCountWarning>
                <Icon icon={faExclamationCircle} size="xs" /> Max results reached. Please refine your search to be more specific.
            </MaxCountWarning>
        ) : (
            <TotalCount>{sortedSearchResults.length} records match your search</TotalCount>
        );
    };
    return (
        <div>
            <MiddleRow>
                <DownloadButton data={sortedSearchResults} filename="searchresults" />
                <div>{renderCountBadge()}</div>
            </MiddleRow>
            <BaseTable
                columnData={COLUMN_DATA}
                sortBy={sorting.sortBy}
                orderBy={sorting.orderBy}
                toggleSort={toggleSort}
                tableData={sortedSearchResults}
                gridTemplateColumns={`repeat(${COLUMN_DATA.length}, minmax(auto, 1fr))`}
                renderRow={(record: SearchResultDoc, idx: number) => {
                    return (
                        <React.Fragment key={record.documentId}>
                            <TableCell index={idx}>
                                <Link to={`/records/${record.documentId}`}>{record.documentId}</Link>
                            </TableCell>
                            <TableCell index={idx}>{record.recordType}</TableCell>
                            <TableCell index={idx}>{record.docType}</TableCell>
                            <TableCell index={idx}>{record.status}</TableCell>
                            <TableCell index={idx}>{record.customerNumber}</TableCell>
                            <TableCell index={idx}>{record.webOrderNumber}</TableCell>
                            <TableCell index={idx}>{record.firstName}</TableCell>
                            <TableCell index={idx}>{record.lastName}</TableCell>
                            <TableCell index={idx}>{record.city}</TableCell>
                            <TableCell index={idx}>{record.state}</TableCell>
                            <TableCell index={idx}>{formatDate(record.ingestDate)}</TableCell>
                        </React.Fragment>
                    );
                }}
                renderNoData={() => {
                    return (
                        <FullRowTableCell index={0}>
                            <NoResultsLabel>There are no records that match this combination of filters and search. Please try again.</NoResultsLabel>
                        </FullRowTableCell>
                    );
                }}
            />
        </div>
    );
};

export default SearchResultsTable;
