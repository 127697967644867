import { blankRecord, BipsliDocument } from '../../models';

export interface RecordDetailPageState {
    editedRecord: BipsliDocument;
    editingFields: Array<keyof BipsliDocument>;
    recordDetail: BipsliDocument;
    showOriginalRecord: boolean;
}

export const initialState: RecordDetailPageState = {
    editedRecord: {
        ...blankRecord,
    },
    editingFields: [],
    recordDetail: {
        ...blankRecord,
    },
    showOriginalRecord: false,
};
