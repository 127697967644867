import * as React from 'react';
import styled from 'styled-components';

import { useAppState } from '../../context';
import { PaymentInstrument } from '../../models/PaymentInstruments';
import { Payment } from '../../models';
import CreditCardPayment from './CreditCardPayment';
import FinancePayment from './FinancePayment';

const LinesHeader = styled.div`
    color: var(--eerie-black);
    font-family: var(--open-sans-bold-font);
    font-size: 20px;
    margin-bottom: 3px;
    padding: 10px;
`;

const OrderPayments: React.FC = () => {
    const {
        recordDetailPage: { recordDetail },
    } = useAppState();

    const renderOrderPayments = (payments: Payment[]) =>
        payments.map((payment, idx) => {
            if (payment.PaymentInstrument && [PaymentInstrument.CREDIT_CARD, PaymentInstrument.APPLE_PAY, PaymentInstrument.PAYPAL].includes(payment.PaymentInstrument.toLowerCase() as PaymentInstrument)) {
                return <CreditCardPayment payment={payment} key={idx} ingestorVersion={recordDetail.IngestorVersion}></CreditCardPayment>;
            } else {
                return <FinancePayment payment={payment} key={idx} ingestorVersion={recordDetail.IngestorVersion}></FinancePayment>;
            }
        });

    return (
        recordDetail.OrderPayments &&
        recordDetail.OrderPayments.length > 0 && (
            <>
                <LinesHeader>Payments</LinesHeader>
                {renderOrderPayments(recordDetail.OrderPayments ?? [])}
            </>
        )
    );
};

export default OrderPayments;
