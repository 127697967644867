import * as React from 'react';
import styled from 'styled-components';

import { ReactComponent as CheckboxSelected } from '../../images/checkbox-selected.svg';
import { ReactComponent as CheckboxUnselected } from '../../images/checkbox-unselected.svg';

interface BorderStyles {
    readonly showBorder?: boolean;
}

const Wrapper = styled.div<BorderStyles>`
    border: ${({ showBorder }) => (showBorder ? '1px solid;' : '')};
    align-items: center;
    width: ${({ showBorder }) => (showBorder ? '20px;' : '')};
    cursor: pointer;
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 8px;
    justify-content: start;
`;

const Label = styled.div`
    font-size: 13px;
`;

interface Props {
    isChecked: boolean;
    label?: string;
    onClick: () => void;
    showBorder?: boolean;
}

export const Checkbox: React.FC<Props> = ({ isChecked, label, onClick, showBorder }) => {
    return showBorder ? (
        <Wrapper showBorder={showBorder} onClick={onClick}>
            {isChecked ? <CheckboxSelected /> : <CheckboxUnselected />}
        </Wrapper>
    ) : (
        <Wrapper showBorder={showBorder} onClick={onClick}>
            {isChecked ? <CheckboxSelected /> : <CheckboxUnselected />}
            <Label>{label}</Label>
        </Wrapper>
    );
};
