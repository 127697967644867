import * as React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { clearEditingFields, clearRecords, useAppDispatch, useAppState } from '../../context';
import { Header, ModalShell } from '../common';
import { useFetchRecordDetail } from '../record-detail/Hooks';
import EditedBanner from '../record-detail/EditedBanner';
import SummaryActions from '../record-detail/SummaryActions';
import FormBanners from '../record-detail/FormBanners';
import DetailForm from '../record-detail/DetailForm';
import RecordHistory from '../record-detail/RecordHistory';
import { FullPageSpinner } from '../common/FullPageSpinner';

const Wrapper = styled.div`
    padding-bottom: 40px;
`;

const FormWrapper = styled.div`
    margin: 30px 40px 0 0;
`;

const RecordDetailPage: React.FC = () => {
    const {
        recordDetailPage: { recordDetail },
    } = useAppState();
    const dispatch = useAppDispatch();
    const { fetchRecordDetail } = useFetchRecordDetail();
    const { id } = useParams<{ id: string }>();

    React.useEffect(() => {
        fetchRecordDetail(id);
        dispatch(clearEditingFields());
        return () => dispatch(clearRecords());
        // eslint-disable-next-line
    }, [dispatch, id]);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Wrapper>
            <FullPageSpinner />
            <Header />
            {recordDetail.IsEdited ? <EditedBanner /> : null}
            <SummaryActions />
            <FormWrapper>
                <FormBanners />
                <DetailForm />
            </FormWrapper>
            <RecordHistory />
            <ModalShell />
        </Wrapper>
    );
};

export default RecordDetailPage;
