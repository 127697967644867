import moment, { Moment } from 'moment';

import { GlobalFilterAction as Action, GlobalFilterActionType as ActionType } from './Action';
import { GlobalFiltersState as State } from './State';
import { RecordStatus, RecordTypes } from '../../models';
import { DateFilterMode } from '../../models/DateFilterMode';

// Check if start and end date are the same
// and start time is not before the end time
const invalidStartEndTimes = (start: Moment, end: Moment) => {
    return start.format('MM/DD/YYYY') === end.format('MM/DD/YYYY') && start.hour() > end.hour();
};

const checkExhaustive = (_: never): void => {};

export const globalFiltersReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionType.FILTER_BY_RECORD_TYPE: {
            const isSelected = state.pendingFilters.recordTypes.includes(action.recordType);
            return {
                ...state,
                pendingFilters: {
                    ...state.pendingFilters,
                    recordTypes: isSelected ? state.pendingFilters.recordTypes.filter((rt) => rt !== action.recordType) : [...state.pendingFilters.recordTypes, action.recordType],
                },
                filtersAreChangedButUnapplied: true,
            };
        }
        case ActionType.FILTER_BY_RECORD_TYPE_IMMEDIATE: {
            return {
                ...state,
                currentFilters: {
                    ...state.currentFilters,
                    recordTypes: state.currentFilters.recordTypes.filter((rt) => rt !== action.recordType),
                },
                pendingFilters: {
                    ...state.pendingFilters,
                    recordTypes: state.pendingFilters.recordTypes.filter((rt) => rt !== action.recordType),
                },
            };
        }
        case ActionType.TOGGLE_FILTER_BY_RECORD_TYPE_ALL: {
            return {
                ...state,
                pendingFilters: {
                    ...state.pendingFilters,
                    recordTypes: [...Object.values(RecordTypes)],
                },
                filtersAreChangedButUnapplied: true,
            };
        }
        case ActionType.TOGGLE_FILTER_BY_STATUS: {
            const isSelected = state.pendingFilters.recordStatus.includes(action.status);
            return {
                ...state,
                pendingFilters: {
                    ...state.pendingFilters,
                    recordStatus: isSelected ? state.pendingFilters.recordStatus.filter((rs) => rs !== action.status) : [...state.pendingFilters.recordStatus, action.status],
                },
                filtersAreChangedButUnapplied: true,
            };
        }
        case ActionType.TOGGLE_FILTER_BY_STATUS_IMMEDIATE: {
            return {
                ...state,
                currentFilters: {
                    ...state.currentFilters,
                    recordStatus: state.currentFilters.recordStatus.filter((rs) => rs !== action.status),
                },
                pendingFilters: {
                    ...state.pendingFilters,
                    recordStatus: state.pendingFilters.recordStatus.filter((rs) => rs !== action.status),
                },
            };
        }
        case ActionType.TOGGLE_FILTER_BY_STATUS_ALL: {
            return {
                ...state,
                pendingFilters: {
                    ...state.pendingFilters,
                    recordStatus: [...Object.values(RecordStatus)],
                },
                filtersAreChangedButUnapplied: true,
            };
        }
        case ActionType.SET_START_DATE: {
            const startDate = moment([action.year, action.month, action.day, action.hour]);
            const isInvalid = invalidStartEndTimes(startDate, state.pendingFilters.dateRange.end);
            return {
                ...state,
                pendingFilters: {
                    ...state.pendingFilters,
                    dateRange: {
                        ...state.pendingFilters.dateRange,
                        start: isInvalid ? state.pendingFilters.dateRange.end : startDate,
                        end: isInvalid ? startDate : state.pendingFilters.dateRange.end,
                    },
                },
                filtersAreChangedButUnapplied: true,
            };
        }
        case ActionType.SET_END_DATE: {
            const endDate = moment([action.year, action.month, action.day, action.hour]);
            const isInvalid = invalidStartEndTimes(state.pendingFilters.dateRange.start, endDate);
            return {
                ...state,
                pendingFilters: {
                    ...state.pendingFilters,
                    dateRange: {
                        ...state.pendingFilters.dateRange,
                        start: isInvalid ? endDate : state.pendingFilters.dateRange.start,
                        end: isInvalid ? state.pendingFilters.dateRange.start : endDate,
                    },
                },
                filtersAreChangedButUnapplied: true,
                dateRangeGreaterThanThirtyDays: action.dateDiff > 30 ? true : false,
            };
        }
        case ActionType.SET_DATE_RANGE: {
            const startDate = moment([action.startYear, action.startMonth, action.startDay, action.startHour]);
            const endDate = moment([action.endYear, action.endMonth, action.endDay, action.endHour]);
            const isInvalid = invalidStartEndTimes(startDate, endDate);
            return {
                ...state,
                pendingFilters: {
                    ...state.pendingFilters,
                    dateRange: {
                        ...state.pendingFilters.dateRange,
                        start: isInvalid ? endDate : startDate,
                        end: isInvalid ? startDate : endDate,
                    },
                },
                filtersAreChangedButUnapplied: true,
                dateRangeGreaterThanThirtyDays: action.dateDiff > 29 ? true : false,
            };
        }
        case ActionType.TOGGLE_DATE_FILTER_MODE: {
            const current = state.pendingFilters.dateFilterMode;
            return {
                ...state,
                pendingFilters: {
                    ...state.pendingFilters,
                    dateFilterMode: current === DateFilterMode.IngestDate ? DateFilterMode.LastTransition : DateFilterMode.IngestDate,
                },
                filtersAreChangedButUnapplied: true,
            };
        }
        case ActionType.RESET_FILTERS_TO_DEFAULT: {
            return {
                currentFilters: {
                    recordStatus: [],
                    recordTypes: [],
                    dateRange: {
                        start: moment().startOf('day').subtract(7, 'days'),
                        end: moment().endOf('day'),
                    },
                    dateFilterMode: DateFilterMode.IngestDate,
                },
                pendingFilters: {
                    recordStatus: [],
                    recordTypes: [],
                    dateRange: {
                        start: moment().startOf('day').subtract(7, 'days'),
                        end: moment().endOf('day'),
                    },
                    dateFilterMode: DateFilterMode.IngestDate,
                },
                filtersAreChangedButUnapplied: false,
                customFiltersAreApplied: false,
                dateRangeGreaterThanThirtyDays: false,
            };
        }
        case ActionType.APPLY_FILTERS: {
            return {
                ...state,
                currentFilters: { ...state.pendingFilters },
                filtersAreChangedButUnapplied: false,
                customFiltersAreApplied: true,
                dateRangeGreaterThanThirtyDays: false,
            };
        }
        case ActionType.DISCARD_PENDING_FILTERS: {
            return {
                ...state,
                pendingFilters: { ...state.currentFilters },
                filtersAreChangedButUnapplied: false,
                dateRangeGreaterThanThirtyDays: false,
            };
        }
        case ActionType.FILTERS_ARE_APPLIED: {
            return {
                ...state,
                filtersAreApplied: action.filtersAreApplied,
            };
        }
        default:
            checkExhaustive(action);
            return state;
    }
};
