import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown, IconDefinition } from '@fortawesome/free-solid-svg-icons';

import { ColumnSort } from '../../models';

const HeaderRowCell = styled.div`
    align-items: center;
    background-color: white;
    cursor: pointer;
    display: grid;
    font-size: 14px;
    grid-auto-flow: column;
    height: 50px;
    justify-content: space-between;
    padding-left: 18px;
    padding-right: 15px;
`;

const IconWapper = styled.div`
    margin-left: 13px;
`;

const ICON_MAP: { [key in ColumnSort]: IconDefinition } = {
    [ColumnSort.ASC]: faSortUp,
    [ColumnSort.DESC]: faSortDown,
    [ColumnSort.NONE]: faSort,
};

interface Props {
    children: React.ReactNode;
    sorting: ColumnSort;
    onClick: () => void;
}

const SortableHeaderRowCell: React.FC<Props> = ({ children, sorting, onClick }: Props) => {
    return (
        <HeaderRowCell onClick={onClick}>
            {children}
            <IconWapper>
                <FontAwesomeIcon icon={ICON_MAP[sorting]} size="xs" />
            </IconWapper>
        </HeaderRowCell>
    );
};

export default SortableHeaderRowCell;
