import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTimes } from '@fortawesome/free-solid-svg-icons';

const ReadOnly = styled.div`
    align-items: center;
    background-color: white;
    border-radius: 20px;
    border: 1px solid var(--platinum);
    box-sizing: border-box;
    color: var(--eerie-black);
    cursor: pointer;
    display: grid;
    font-size: 13px;
    grid-template-columns: 1fr auto;
    height: 40px;
    padding: 0 15px;
`;

const InputWrapper = styled.div`
    align-items: center;
    display: flex;
    position: relative;
    width: 408px;
`;

const InputButtons = styled.div`
    cursor: pointer;
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 10px;
    position: absolute;
    right: 15px;
`;

const SaveButton = styled.div`
    background-color: var(--french-blue);
    border-radius: 10px;
    color: white;
    font-family: var(--open-sans-semibold-font);
    font-size: 10px;
    line-height: 19px;
    padding: 0 12px;
`;

const StyledInput = styled.input`
    border-radius: 20px;
    border: 1px solid var(--platinum);
    box-sizing: border-box;
    color: var(--eerie-black);
    font-family: var(--open-sans-font);
    font-size: 13px;
    height: 40px;
    outline: none;
    padding-left: 15px;
    width: 100%;
`;

interface Props {
    isEditMode: boolean;
    onChange: (newValue: string) => void;
    onRevert: () => void;
    onSave: () => void;
    onToggleEditMode: () => void;
    value: string;
}

const EditField: React.FC<Props> = ({
    isEditMode,
    onChange,
    onRevert,
    onSave,
    onToggleEditMode,
    value,
}) => {
    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        if (isEditMode && inputRef.current) {
            inputRef.current.select();
        }
    }, [isEditMode]);

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.currentTarget.value);
    };

    const onSaveHandler = () => {
        onSave();
        onToggleEditMode();
    };

    const onCancelHandler = () => {
        onRevert();
        onToggleEditMode();
    };

    return isEditMode ? (
        <InputWrapper>
            <StyledInput
                type="text"
                value={value}
                onChange={onChangeHandler}
                ref={inputRef}
            />
            <InputButtons>
                <SaveButton onClick={onSaveHandler}>Save</SaveButton>
                <FontAwesomeIcon
                    icon={faTimes}
                    size="lg"
                    color="var(--rose-madder)"
                    onClick={onCancelHandler}
                />
            </InputButtons>
        </InputWrapper>
    ) : (
        <ReadOnly onClick={onToggleEditMode}>
            <div>{value}</div>
            <FontAwesomeIcon
                icon={faPen}
                size="sm"
                color="var(--french-blue)"
            />
        </ReadOnly>
    );
};

export default EditField;
