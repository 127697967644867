import styled from 'styled-components';

interface ButtonStyles {
    readonly active: boolean;
}

export const Widget = styled.div`
    background-color: white;
    border-radius: 10px;
    box-sizing: border-box;
    color: var(--eerie-black);
    font-size: 11px;
`;

export const Table = styled.div`
    background-color: var(--platinum);
    border-radius: 10px;
    border: 1px solid var(--platinum);
    display: grid;
    grid-gap: 1px;
    grid-template-columns: 1fr 150px 100px 1fr;
    overflow: hidden;
`;

export const HeaderBorder = styled.div`
    background-color: var(--french-blue);
    grid-column: 1 / -1;
    height: 1px;
`;

export const TableCell = styled.div`
    align-items: center;
    background-color: white;
    display: flex;
    min-height: 30px;
    padding: 0 11px;
    white-space: nowrap;
`;
export const TableHeader = styled(TableCell)`
    font-weight: bold;
    color: var(--mostly-gray);
`;

export const Title = styled.div`
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 10px;
`;
export const StateToggle = styled.div`
    display: inline-block;
    cursor: pointer;
    font-weight: bold;
    padding: 5px 10px;
`;
export const LimitInputBox = styled.input`
    border-radius: 15px;
    border: 1px solid var(--platinum);
    box-sizing: border-box;
    color: var(--eerie-black);
    font-family: var(--open-sans-font);
    font-size: 13px;
    height: 33px;
    outline: none;
    padding-left: 15px;
    width: 50px;
    margin: 5px 0;
`;
export const ButtonGroup = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
`;
export const Blurb = styled.div`
    margin: 5px 0 10px 0;
    font-size: 14px;
`;
