import * as React from 'react';
import styled from 'styled-components';
import { Payment } from '../../models';
import { pciSecure } from '../auth/PCISecure';
import { formatDate } from '../common';
import { useGetDecryptedPaymentInfo } from './Hooks';
import DecryptButton from './DecryptButton';

const RevealButton = pciSecure(DecryptButton);

const Wrapper = styled.div`
    box-sizing: border-box;
    grid-column: span 2;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 10px;
    margin-bottom: 50px;
    font-size: 14px;
`;
const PaymentLineCell = styled.div`
    padding: 10px;
    border-bottom: 1px solid var(--cultured);
`;
const PaymentLineHeaderCell = styled.div`
    color: var(--eerie-black);
    font-family: var(--open-sans-bold-font);
    font-size: 13px;
    margin-bottom: 3px;
    padding: 10px;
    border-bottom: 1px solid var(--cultured);
`;

interface Props {
    payment: Payment;
    ingestorVersion: string;
}
const FinancePayment: React.FC<Props> = ({ payment, ingestorVersion }) => {
    const { getDecryptedPaymentInfo } = useGetDecryptedPaymentInfo();
    const clickDecrypt = () => {
        getDecryptedPaymentInfo(payment.CCNumber, ingestorVersion, false);
    };

    return (
        <Wrapper>
            <PaymentLineHeaderCell>Method</PaymentLineHeaderCell>
            <PaymentLineHeaderCell>Type</PaymentLineHeaderCell>
            <PaymentLineHeaderCell>Amount</PaymentLineHeaderCell>
            <PaymentLineHeaderCell>Auth Date</PaymentLineHeaderCell>
            <PaymentLineHeaderCell>Account #</PaymentLineHeaderCell>
            <PaymentLineHeaderCell>Term</PaymentLineHeaderCell>
            <PaymentLineHeaderCell>APN Accept date</PaymentLineHeaderCell>
            <PaymentLineHeaderCell>APN Accepted</PaymentLineHeaderCell>
            <PaymentLineHeaderCell>APN Disclosure</PaymentLineHeaderCell>
            <PaymentLineCell>{payment.PaymentInstrument}</PaymentLineCell>
            <PaymentLineCell>{payment.CCType}</PaymentLineCell>
            <PaymentLineCell>{payment.Amount}</PaymentLineCell>
            <PaymentLineCell>{formatDate(payment.AuthDate)}</PaymentLineCell>
            <PaymentLineCell>
                <RevealButton onClick={clickDecrypt} visible={payment.CCNumber} ccNum={payment.Decrypted && payment.CCNumber} />
            </PaymentLineCell>
            <PaymentLineCell>{payment.PaymentTerm}</PaymentLineCell>
            <PaymentLineCell>{payment.APNAccepted}</PaymentLineCell>
            <PaymentLineCell>{formatDate(payment.APNAcceptanceDate)}</PaymentLineCell>
            <PaymentLineCell>{payment.APNDisclosure}</PaymentLineCell>
        </Wrapper>
    );
};

export default FinancePayment;
