import { RecordStatus } from './RecordStatus';
import { RecordTypes } from './RecordTypes';
import { IngestorVersion } from './IngestorVersion';
import { ChangeHistory, DocumentError } from '.';

export interface LineItem {
    ItemNumber: string;
    PromisedPeriodCode: string;
    Quantity: number;
}

export interface Payment {
    Amount: number;
    AuthDate: string;
    CCExpDate: string;
    CCNumber: string;
    CCType: string;
    CyberSourceAuthNum: string;
    CyberSourceSeqNum: string;
    PaymentInstrument: string;
    PaymentTerm: string;
    APNAcceptanceDate: string;
    APNAccepted: string;
    APNDisclosure: string;
    Decrypted: boolean;
}
export interface BipsliDocument {
    ADID?: string;
    CompletedDate?: string;
    CreatedDate?: string;
    CustomerNumber?;
    DocType?: string;
    ErrorDate?: string;
    Errors?: DocumentError[];
    History?: ChangeHistory[];
    Id: string;
    IngestDate?: string;
    IngestorVersion: IngestorVersion;
    IsEdited: boolean;
    LastStateTransition?: string;
    LineItems?: LineItem[];
    NextStep?: string;
    Origin?: string;
    OriginalJson?: string;
    OrderPayments?: Payment[];
    PaymentType?: string;
    ProcessingStartTime?: string;
    RecordType: RecordTypes;
    SalesOrderNumber?: string;
    Status: RecordStatus;
    WebOrderNumber?: string;
}

export const blankRecord: BipsliDocument = {
    Id: '',
    IsEdited: false,
    Status: null,
    Errors: [],

    History: [],
    IngestorVersion: IngestorVersion.Version_1_0,
    RecordType: RecordTypes.EmailOnly,
};
