import * as React from 'react';
import styled from 'styled-components';

import { useAppState } from '../../context';
import { BipsliDocument, RecordStatus } from '../../models';
import { formatDate } from '../common';

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto 1fr auto 1fr auto 1fr auto;
`;

const Detail = styled.div`
    color: var(--eerie-black);
    font-size: 13px;
`;

const BadgeWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
`;

interface BadgeStyles {
    readonly disabled: boolean;
    readonly color?: string;
}

const Badge = styled.div<BadgeStyles>`
    background-color: var(${(props: any) => (props.disabled ? '--french-blue-disabled' : props.color ?? '--french-blue')});
    border-radius: 10px;
    color: white;
    font-family: var(--open-sans-bold-font);
    font-size: 13px;
    height: 25px;
    line-height: 25px;
    padding: 0 15px;
`;

const Line = styled.div<BadgeStyles>`
    background-color: var(${(props: any) => (props.disabled ? '--french-blue-disabled' : '--french-blue')});
    height: 1px;
    margin-left: -20%;
    margin-right: -20%;
    margin-top: 12.5px;
    position: relative;
    z-index: -1;
`;

const mapDates = (
    record: BipsliDocument
): {
    label: string;
    showLine: boolean;
    detail?: string;
    color?: string;
}[] => {
    const isDiscarded = record.Status === RecordStatus.Discarded;
    return [
        { label: 'Web Entry', showLine: false, detail: record.CreatedDate },
        { label: 'Ingested', showLine: true, detail: record.IngestDate },
        { label: 'Processing', showLine: true, detail: record.ProcessingStartTime },
        {
            label: 'Errored',
            showLine: true,
            detail: record.ErrorDate,
            color: '--rose-madder',
        },
        {
            label: !isDiscarded ? 'Complete' : 'Discarded',
            showLine: true,
            detail: !isDiscarded ? record.CompletedDate : record.LastStateTransition,
        },
    ];
};

const RecordTimeline: React.FC = () => {
    const {
        recordDetailPage: { recordDetail },
    } = useAppState();

    const renderBadge = (label: string, showLine: boolean, detail?: string, color?: string) => {
        return (
            <React.Fragment key={label}>
                {showLine ? <Line disabled={!detail} /> : null}
                <BadgeWrapper key={label}>
                    <Badge disabled={!detail} color={color}>
                        {label}
                    </Badge>
                    <Detail>{!detail ? '- -' : formatDate(detail)}</Detail>
                </BadgeWrapper>
            </React.Fragment>
        );
    };

    return <Wrapper>{mapDates(recordDetail).map((e) => renderBadge(e.label, e.showLine, e.detail, e.color))}</Wrapper>;
};

export default RecordTimeline;
