import * as React from 'react';
import styled from 'styled-components';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';

const Button = styled.div`
    background-color: var(--french-blue);
    border-radius: 15px;
    color: white;
    font-family: var(--open-sans-semibold);
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    white-space: nowrap;
    text-decoration: none;
    display: inline-block;
    grid-auto-flow: column;
    grid-column-gap: 5px;
    cursor: pointer;
    &:hover {
        background-color: var(--french-blue-hover);
    }
`;
const Icon = styled(FontAwesomeIcon)`
    position: relative;
    top: -2px;
    margin-right: 5px;
`;

interface Props {
    data: any;
    filename: string;
}

const DownloadButton: React.FC<Props> = ({ data, filename }) => {
    let utcDate = new Date().toLocaleString();
    let fullname = `bipsli_${filename}_${utcDate}.csv`;
    // Deep copy, so the following cleanup doesn't effect references
    let d = JSON.parse(JSON.stringify(data));
    // Get rid of JSON commas, CSV format is finicky
    let escapedData = d.map((row) => {
        if (row.errorMessage) {
            row.errorMessage = JSON.stringify(row.errorMessage).replaceAll(',', ';');
        }
        return row;
    });
    return (
        <CSVLink data={escapedData} filename={fullname} enclosingCharacter='"' target="_blank">
            <Button>
                <Icon icon={faFileDownload} size="xs" />
                <span>Download Report</span>
            </Button>
        </CSVLink>
    );
};

export default DownloadButton;
