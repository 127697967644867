import * as React from 'react';
import styled from 'styled-components';

const Banner = styled.div`
    align-items: center;
    background-color: var(--international-orange);
    border-top-left-radius: 10px;
    color: white;
    display: flex;
    font-family: var(--open-sans-semibold-font);
    font-size: 20px;
    height: 60px;
    padding-left: 15px;
`;

const DiscardedBanner: React.FC = () => {
    return <Banner>Discarded Record</Banner>;
};

export default DiscardedBanner;
