import { AppAction } from './AppAction';
import { AppState } from './AppState';
import { globalFiltersReducer } from './global-filter/Reducer';
import { GlobalFilterAction } from './global-filter/Action';
import { DashboardPageAction } from './dashboard-page/Action';
import { dashboardPageReducer } from './dashboard-page/Reducer';
import { RecordDetailPageAction } from './record-detail-page/Action';
import { recordDetailPageReducer } from './record-detail-page/Reducer';
import { SearchPageAction } from './search-page/Action';
import { searchPageReducer } from './search-page/Reducer';
import { ModalAction } from './modal/Action';
import { modalReducer } from './modal/Reducer';
import { spinnerReducer } from './spinner/Reducer';
import { SpinnerAction } from './spinner/Action';
import { adminPageReducer } from './admin-page/Reducer';
import { AdminPageAction } from './admin-page/Action';

export const appReducer = (state: AppState, action: AppAction): AppState => {
    return {
        ...state,
        globalFilters: globalFiltersReducer(state.globalFilters, action as GlobalFilterAction),
        dashboardPage: dashboardPageReducer(state.dashboardPage, action as DashboardPageAction),
        modal: modalReducer(state.modal, action as ModalAction),
        recordDetailPage: recordDetailPageReducer(state.recordDetailPage, action as RecordDetailPageAction),
        searchPage: searchPageReducer(state.searchPage, action as SearchPageAction),
        spinner: spinnerReducer(state.spinner, action as SpinnerAction),
        adminPage: adminPageReducer(state.adminPage, action as AdminPageAction),
    };
};
