import * as React from 'react';
import styled from 'styled-components';

import { useAppState } from '../../context';
import { LineItem } from '../../models';

const Wrapper = styled.div`
    box-sizing: border-box;
    grid-column: span 2;
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    gap: 10px;
    margin-bottom: 50px;
    font-size: 14px;
`;
const OrderLineCell = styled.div`
    padding: 10px;
    border-bottom: 1px solid var(--cultured);
`;
const OrderLineHeaderCell = styled.div`
    color: var(--eerie-black);
    font-family: var(--open-sans-bold-font);
    font-size: 13px;
    margin-bottom: 3px;
    padding: 10px;
    border-bottom: 1px solid var(--cultured);
`;
const LinesHeader = styled.div`
    color: var(--eerie-black);
    font-family: var(--open-sans-bold-font);
    font-size: 20px;
    margin-bottom: 3px;
    padding: 10px;
`;

const OrderLines: React.FC = () => {
    const {
        recordDetailPage: { recordDetail },
    } = useAppState();

    const renderOrderLines = (lines: LineItem[]) =>
        lines.map((ol, idx) => {
            return (
                <React.Fragment key={idx}>
                    <OrderLineCell>{ol.ItemNumber}</OrderLineCell>
                    <OrderLineCell>{ol.PromisedPeriodCode}</OrderLineCell>
                    <OrderLineCell>{ol.Quantity}</OrderLineCell>
                </React.Fragment>
            );
        });

    return (
        recordDetail.LineItems &&
        recordDetail.LineItems.length > 0 && (
            <>
                <LinesHeader>Order Lines</LinesHeader>
                <Wrapper>
                    <OrderLineHeaderCell>Item Number</OrderLineHeaderCell>
                    <OrderLineHeaderCell>Promise Period</OrderLineHeaderCell>
                    <OrderLineHeaderCell>Quantity</OrderLineHeaderCell>
                    {renderOrderLines(recordDetail.LineItems ?? [])}
                </Wrapper>
            </>
        )
    );
};

export default OrderLines;
