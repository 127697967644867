import RequestBuilder from './RequestBuilder';
import { environment } from '../environment';
import { IngestorV2StatusResponse } from '../models/response/IngestorStatusResponse';
import { UpdateIngestorRequest, UpdateIngestorV2Request } from '../models/request/UpdateIngestorRequest';

const API_BASE_URL = environment.baseUrl;

const getIngestorV2Status = async (): Promise<IngestorV2StatusResponse[]> => {
    return RequestBuilder.getInstance().get(`${API_BASE_URL}/api/ingestor/v2/configuration`);
};

const setIngestorConfig = async (request: UpdateIngestorRequest): Promise<void> => {
    return RequestBuilder.getInstance().put(`${API_BASE_URL}/api/ingestor/v1/configuration`, request);
};

const setIngestorV2Config = async (request: UpdateIngestorV2Request): Promise<void> => {
    return RequestBuilder.getInstance().put(`${API_BASE_URL}/api/ingestor/v2/configuration`, request);
};

export default {
    getIngestorV2Status,
    setIngestorConfig,
    setIngestorV2Config,
};
