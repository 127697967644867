import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    align-items: center;
    cursor: pointer;
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 8px;
    justify-content: start;
`;

const Label = styled.div`
    font-size: 13px;
`;

const RadioUnselected = styled.div`
    background-color: white;
    border-radius: 50%;
    border: 1px solid var(--platinum);
    height: 20px;
    width: 20px;
`;

const SelectedOuter = styled.div`
    align-items: center;
    background-color: var(--forest-green);
    border-radius: 50%;
    border: 1px solid var(--platinum);
    display: flex;
    height: 20px;
    justify-content: center;
    width: 20px;
`;

const SelectedInner = styled.div`
    background-color: var(--peas-green);
    border-radius: 50%;
    height: 10px;
    width: 10px;
`;

interface Props {
    isSelected: boolean;
    label: string;
    onClick: () => void;
}

export const RadioButton: React.FC<Props> = ({
    isSelected,
    label,
    onClick,
}) => {
    const renderSelected = () => {
        return (
            <SelectedOuter>
                <SelectedInner />
            </SelectedOuter>
        );
    };

    return (
        <Wrapper onClick={onClick}>
            {isSelected ? renderSelected() : <RadioUnselected />}
            <Label>{label}</Label>
        </Wrapper>
    );
};
