import { GlobalFiltersState, globalFiltersInitialState } from './global-filter/State';
import { DashboardPageState, dashboardPageInitialState } from './dashboard-page/State';
import { RecordDetailPageState, initialState as recordDetailPageInitialState } from './record-detail-page/State';
import { SearchPageState, initialState as searchPageInitialState } from './search-page/State';
import { ModalState, initialState as modalInitialState } from './modal/State';
import { SpinnerState, initialState as spinnerInitialState } from './spinner/State';
import { AdminPageState, initialState as adminPageInitialState } from './admin-page/State';

export interface AppState {
    dashboardPage: DashboardPageState;
    globalFilters: GlobalFiltersState;
    modal: ModalState;
    recordDetailPage: RecordDetailPageState;
    searchPage: SearchPageState;
    spinner: SpinnerState;
    adminPage: AdminPageState;
}

export const initialState: AppState = {
    dashboardPage: dashboardPageInitialState,
    globalFilters: globalFiltersInitialState,
    modal: modalInitialState,
    recordDetailPage: recordDetailPageInitialState,
    searchPage: searchPageInitialState,
    spinner: spinnerInitialState,
    adminPage: adminPageInitialState,
};
