import { ModalType } from '../../models';

export enum ModalActionType {
    SHOW_DISCARD_MODAL = 'SHOW_DISCARD_MODAL',
    SHOW_RESTART_MODAL = 'SHOW_RESTART_MODAL',
    SHOW_ERROR_MODAL = 'SHOW_ERROR_MODAL',
    SHOW_ERROR_DETAIL_MODAL = 'SHOW_ERROR_DETAIL_MODAL',
    CLOSE_MODAL = 'CLOSE_MODAL',
    TOGGLE_CONFIRM_DISCARD = 'TOGGLE_CONFIRM_DISCARD',
    TOGGLE_CONFIRM_RESTART = 'TOGGLE_CONFIRM_RESTART',
    TOGGLE_CONFIRM_DISCARD_ALL = 'TOGGLE_CONFIRM_DISCARD_ALL',
    TOGGLE_CANCELED_DISCARD_ALL = 'TOGGLE_CANCELED_DISCARD_ALL',
}

export type ModalAction =
    | { type: ModalActionType.CLOSE_MODAL }
    | {
          type: ModalActionType.SHOW_DISCARD_MODAL;
          modalType: ModalType;
          recordId: string;
          recordType: string;
          recordStatus: string;
          recordsLength: number;
      }
    | {
          type: ModalActionType.SHOW_RESTART_MODAL;
          modalType: ModalType;
          recordId: string;
          recordType: string;
          recordStatus: string;
      }
    | {
          type: ModalActionType.SHOW_ERROR_MODAL;
          modalType: ModalType;
          data: any;
      }
    | {
          type: ModalActionType.SHOW_ERROR_DETAIL_MODAL;
          modalType: ModalType;
          data: any;
      }
    | { type: ModalActionType.TOGGLE_CONFIRM_DISCARD }
    | { type: ModalActionType.TOGGLE_CONFIRM_RESTART }
    | { type: ModalActionType.TOGGLE_CONFIRM_DISCARD_ALL }
    | { type: ModalActionType.TOGGLE_CANCELED_DISCARD_ALL };
