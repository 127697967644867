import * as React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { FilterBadge } from '../common';

const Wrapper = styled.div`
    align-content: start;
    display: grid;
    grid-gap: 6px;
    justify-content: start;
`;

const Label = styled.div`
    font-size: 13px;
`;

const BadgeWrapper = styled.div`
    margin-left: -5px;
    margin-top: -5px;
`;

interface Props {
    searchFor: string;
}

const SearchBadge: React.FC<Props> = ({ searchFor }) => {
    const history = useHistory();

    const onClick = () => {
        history.push({
            pathname: `/search`,
            search: window.location.search,
        });
    };

    return (
        <Wrapper>
            <Label>You Searched For...</Label>
            <BadgeWrapper>
                <FilterBadge label={searchFor} onClick={onClick} showCloseButton={true} />
            </BadgeWrapper>
        </Wrapper>
    );
};

export default SearchBadge;
