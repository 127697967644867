import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

const Filters = styled.div`
    align-items: center;
    background-color: var(--french-blue);
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    color: white;
    cursor: pointer;
    display: grid;
    font-family: var(--roboto-font);
    font-size: 12px;
    grid-auto-flow: column;
    grid-column-gap: 8px;
    height: 45px;
    justify-content: center;
`;

interface Props {
    isOpen: boolean;
    onClick: () => void;
}

export const FiltersButton: React.FC<Props> = ({ isOpen, onClick }) => {
    return (
        <Filters onClick={onClick}>
            Filters
            <FontAwesomeIcon
                icon={isOpen ? faChevronLeft : faChevronRight}
                size="1x"
            />
        </Filters>
    );
};
