import * as React from 'react';
import styled from 'styled-components';

import { Payment } from '../../models';
import { PaymentInstrument } from '../../models/PaymentInstruments';
import { pciSecure } from '../auth/PCISecure';
import { formatDate } from '../common';
import DecryptButton from './DecryptButton';
import { useGetDecryptedPaymentInfo } from './Hooks';

const RevealButton = pciSecure(DecryptButton);

const Wrapper = styled.div`
    box-sizing: border-box;
    grid-column: span 2;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    margin-bottom: 50px;
    font-size: 14px;
`;
const PaymentLineCell = styled.div`
    padding: 10px;
    border-bottom: 1px solid var(--cultured);
`;
const PaymentLineHeaderCell = styled.div`
    color: var(--eerie-black);
    font-family: var(--open-sans-bold-font);
    font-size: 13px;
    padding: 10px;
    border-top: 1px solid var(--cultured);
`;

interface Props {
    payment: Payment;
    ingestorVersion: string;
}
const CreditCardPayment: React.FC<Props> = ({ payment, ingestorVersion }) => {
    const { getDecryptedPaymentInfo } = useGetDecryptedPaymentInfo();
    const clickDecrypt = () => {
        getDecryptedPaymentInfo(payment.CCNumber, ingestorVersion);
    };

    return (
        <>
            <Wrapper>
                <PaymentLineHeaderCell>Type</PaymentLineHeaderCell>
                <PaymentLineHeaderCell>Amount</PaymentLineHeaderCell>
                <PaymentLineHeaderCell>Transaction Number</PaymentLineHeaderCell>
                <PaymentLineHeaderCell>CC Type</PaymentLineHeaderCell>
                <PaymentLineHeaderCell>CC Number</PaymentLineHeaderCell>
                <PaymentLineHeaderCell>CC Exp Date</PaymentLineHeaderCell>
                <PaymentLineHeaderCell>Auth Date</PaymentLineHeaderCell>
                <PaymentLineHeaderCell>Approval Code</PaymentLineHeaderCell>
                <PaymentLineCell>{payment.PaymentInstrument}</PaymentLineCell>
                <PaymentLineCell>{payment.Amount}</PaymentLineCell>
                <PaymentLineCell>{payment.CyberSourceSeqNum}</PaymentLineCell>
                <PaymentLineCell>{payment.CCType}</PaymentLineCell>
                <PaymentLineCell>{<RevealButton onClick={clickDecrypt} visible={payment.PaymentInstrument && payment.PaymentInstrument.toLowerCase() === PaymentInstrument.CREDIT_CARD} ccNum={payment.Decrypted && payment.CCNumber} />}</PaymentLineCell>
                <PaymentLineCell>{formatDate(payment.CCExpDate, 'MM/YY')}</PaymentLineCell>
                <PaymentLineCell>{formatDate(payment.AuthDate)}</PaymentLineCell>
                <PaymentLineCell>{payment.CyberSourceAuthNum}</PaymentLineCell>
            </Wrapper>
        </>
    );
};

export default CreditCardPayment;
