import * as React from 'react';
import styled from 'styled-components';

interface ButtonStyles {
    readonly active: boolean;
    readonly activeColor: string;
}
const Wrapper = styled.div`
    font-family: var(--open-sans-semibold-font);
    display: inline-block;
    cursor: pointer;
`;
const Toggle = styled.div`
    display: inline-block;
    cursor: pointer;
    padding: 5px 10px;
`;
const OffState = styled(Toggle)<ButtonStyles>`
    background-color: var(${({ active, activeColor }) => (active ? activeColor : '--barely-gray')});
    color: var(${({ active }) => (active ? '--white' : '--sortof-gray')});
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    &:hover {
        background-color: var(${({ active, activeColor }) => (active ? activeColor : '--cultured')});
    }
`;

const OnState = styled(Toggle)<ButtonStyles>`
    background-color: var(${({ active, activeColor }) => (active ? activeColor : '--barely-gray')});
    color: var(${({ active }) => (active ? '--white' : '--sortof-gray')});
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    &:hover {
        background-color: var(${({ active, activeColor }) => (active ? activeColor : '--cultured')});
    }
`;
const Label = styled.div`
    font-size: 13px;
`;

interface Props {
    active: boolean;
    onLabel: string;
    offLabel: string;
    onColor: string;
    offColor: string;
    onClick: () => void;
}

export const StateToggle: React.FC<Props> = ({ active, onLabel, offLabel, onColor, offColor, onClick }) => {
    return (
        <Wrapper>
            <OnState active={active} onClick={onClick} activeColor={onColor}>
                <Label>{onLabel}</Label>
            </OnState>
            <OffState active={!active} onClick={onClick} activeColor={offColor}>
                <Label>{offLabel}</Label>
            </OffState>
        </Wrapper>
    );
};
