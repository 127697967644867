import { faArrowAltCircleDown, faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import styled from 'styled-components';

import { formatNumber } from '../common';

const Widget = styled.div`
    align-items: center;
    background-color: white;
    border-radius: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 296px;
    justify-content: center;
    padding: 30px 40px;
`;

const Label = styled.span`
    color: var(--eerie-black);
    font-size: 10px;
`;

const Total = styled.span`
    color: var(--eerie-black);
    font-size: 48px;
`;

const Performance = styled.div`
    font-size: 10px;
    align-items: center;
    background-color: white;
    border-top: 1px solid grey;
    box-sizing: border-box;
    justify-content: center;
    padding: 10px 0px;
`;

const PerformanceWarning = styled.span`
    color: var(--sortof-gray);
    font-size: 10px;
    font-style: italic;
`;

interface Props {
    label: string;
    total: number;
    currentAverage: number;
    previousAverage: number;
    desiredOutcome: boolean;
    displayPerformance: boolean;
}

const TotalWidget: React.FC<Props> = ({ label, total, currentAverage, previousAverage, desiredOutcome, displayPerformance }) => {
    const renderDelta = (c?: number, p?: number, desired?: boolean) => {
        if (c === undefined || p === undefined) {
            return null;
        }
        let increasingColor = desiredOutcome === true ? '--sheen-green' : '--rose-madder';
        let decreasingColor = desiredOutcome === true ? '--rose-madder' : '--sheen-green';
        const icon = c < p ? faArrowAltCircleDown : faArrowAltCircleUp;
        const color = c < p ? decreasingColor : increasingColor;
        return <FontAwesomeIcon icon={icon} color={`var(${color})`} size="sm" style={{ marginRight: '5px' }} />;
    };

    const renderPercentChange = (current, previous) => {
        if (previous === undefined) {
            return null;
        }
        if (previous === 0) {
            return null;
        }
        let change = Math.abs(current - previous);
        let percentChange = ((change / previous) * 100).toFixed(1);
        let delta = current >= previous ? 'Above AVG' : 'Below AVG';
        return percentChange + '% ' + delta;
    };

    return (
        <Widget>
            <Label>{label}</Label>
            <Total>
                {displayPerformance ? renderDelta(currentAverage, previousAverage, desiredOutcome) : null}
                {formatNumber(total)}
            </Total>
            {displayPerformance ? (
                <Performance>
                    {currentAverage.toFixed(0)} / Day ({renderPercentChange(currentAverage, previousAverage)})
                </Performance>
            ) : (
                <PerformanceWarning>Stats unavailable</PerformanceWarning>
            )}
        </Widget>
    );
};

export default TotalWidget;
