import * as React from 'react';
import styled from 'styled-components';

import { disableSpinner, enableSpinner, showDiscardModal, showErrorModal, showRestartModal, toggleConfirmDiscard, toggleConfirmRestart, useAppDispatch, useAppState } from '../../context';
import BackButton from './BackButton';
import RetryButton from './RetryButton';
import DiscardButton from './DiscardButton';
import { RecordStatus, BipsliDocument, RecordTypes, ModalType } from '../../models';
import { useFetchRecordDetail } from './Hooks';
import { RecordsSvc } from '../../services';
import { useRetryToast, useDiscardedToast, useRestartedToast } from '../common/Hooks';
import RecordTimeline from './RecordTimeline';
import RestartButton from './RestartButton';
import Link from '@material-ui/core/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../environment';

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 117px 1fr;
    margin-top: 18px;
    padding-right: 40px;
`;

const IdButtonsRow = styled.div`
    align-items: center;
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 1fr auto;
`;

const ButtonsBlock = styled.div`
    align-items: center;
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: auto auto;
`;

const BackButtonWrapper = styled.div`
    align-items: center;
    display: flex;
    height: 44px;
    justify-content: center;
`;

const IdLabel = styled.div`
    color: black;
    font-size: 32px;
`;

const SummaryRow = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 40px;
    justify-content: start;
`;

const SummaryLabel = styled.div`
    color: var(--eerie-black);
    font-family: var(--open-sans-bold-font);
    font-size: 13px;
    line-height: 1.85;
`;

const SummaryValue = styled.div`
    color: var(--eerie-black);
    font-size: 13px;
    font-weight: ${(props: any) => (props.bold ? 'bold' : 'normal')};
`;

const TimelineWrapper = styled.div`
    margin-top: 15px;
    margin-bottom: 20px;
`;

const DocNumber = styled.span`
    font-size: 16px;
    font-family: var(--roboto-font);
    font-weight: bold;
    color: var(--somewhat-gray);
`;
const FaIcon = styled(FontAwesomeIcon)`
    margin-left: 10px;
`;
const DocNum = styled(DocNumber)`
    margin-left: 10px;
`;

// TODO: Add missing data
const buildSummaryItems = (record: BipsliDocument) => {
    let items = [
        { label: 'Step', value: record.NextStep },
        { label: 'Customer ID', value: record.CustomerNumber },
        { label: 'Ad ID', value: record.ADID },
    ];
    if(record.RecordType === RecordTypes.Subscription) {
        items.push(
            {label: 'Migration', value: record['IsMigration'] ? 'Yes': 'No'},
            {label: 'Financed', value: record['IsFinanced'] ? 'Yes': 'No'},
            {label: 'Active Trial', value: record['TrialEndsAt'] ? 'Yes': 'No'},
        )
    }
    if (record.RecordType === RecordTypes.Order || record.RecordType === RecordTypes.Quote || record.RecordType === RecordTypes.Subscription) {
        items.push(
            { label: 'Payment', value: record.PaymentType },
            { label: 'Sales Order Number', value: record.SalesOrderNumber},
            { label: 'Web Order Number', value: record.WebOrderNumber }
        );
    }
    
    if (record.RecordType === RecordTypes.Lead) {
        items.push({ label: 'Source', value: record.Origin });
    }
    if (record.DocType !== record.RecordType) {
        items.push({ label: 'Doctype', value: record.DocType });
    }
    return items;
};

const SummaryActions: React.FC = () => {
    const {
        recordDetailPage: { recordDetail },
        modal: { confirmed, recordId, recordType, recordStatus, type },
    } = useAppState();
    const dispatch = useAppDispatch();
    const { fetchRecordDetail } = useFetchRecordDetail();
    const { retryToast } = useRetryToast();
    const { discardedToast } = useDiscardedToast();
    const { restartedToast } = useRestartedToast();

    const renderSummaryItem = (label: string, value: string) => {
        return (
            <div key={label}>
                <SummaryLabel>{label}</SummaryLabel>
                <SummaryValue>{!value ? '- -' : value}</SummaryValue>
            </div>
        );
    };

    const onRetry = async (id: string, recordType: string, recordStatus: string) => {
        dispatch(enableSpinner());
        const result = await RecordsSvc.putRetry(id, recordType, recordStatus).catch((e) => console.error('Error retrying record', e));
        if (result) {
            fetchRecordDetail(id);
            retryToast();
        } else {
            dispatch(showErrorModal());
        }
        dispatch(disableSpinner());
    };

    React.useEffect(() => {
        if (!confirmed || type !== ModalType.DISCARD) {
            return;
        }
        // TODO: Move this to hooks?
        const postDiscard = async () => {
            dispatch(enableSpinner());
            const result = await RecordsSvc.putDiscard(recordId, recordType, recordStatus).catch((e) => console.error('Error discarding record', e));
            if (result) {
                fetchRecordDetail(recordId);
                discardedToast();
            } else {
                dispatch(showErrorModal());
            }
            // Reset confirm state
            dispatch(toggleConfirmDiscard());
            dispatch(disableSpinner());
        };

        postDiscard();
        // eslint-disable-next-line
    }, [confirmed, recordId, dispatch]);

    React.useEffect(() => {
        if (!confirmed || type !== ModalType.RESTART) {
            return;
        }
        // TODO: Move this to hooks?
        const postRestart = async () => {
            dispatch(enableSpinner());
            const result = await RecordsSvc.putRestart(recordId, recordType, recordStatus).catch((e) => console.error('Error restarting record', e));
            if (result) {
                fetchRecordDetail(recordId);
                restartedToast();
            } else {
                dispatch(showErrorModal());
            }
            // Reset confirm state
            dispatch(toggleConfirmRestart());
            dispatch(disableSpinner());
        };

        postRestart();
        // eslint-disable-next-line
    }, [confirmed, recordId, dispatch]);

    const onDiscard = (id: string, recordType: string, recordStatus: string) => {
        dispatch(showDiscardModal(id, recordType, recordStatus));
    };

    const onRestart = (id: string, recordType: string, recordStatus: string) => {
        dispatch(showRestartModal(id, recordType, recordStatus));
    };

    const buttonsDisabled = [RecordStatus.Complete, RecordStatus.Discarded, RecordStatus.Processing].includes(recordDetail.Status);

    const retryDisabled = recordDetail.Errors.some((e) => !e.callbackToken);

    const ENV_NAME = environment.envName;
    const env = ENV_NAME.charAt(0).toUpperCase() + ENV_NAME.slice(1);
    const docString = `https://us-west-2.console.aws.amazon.com/dynamodbv2/home?region=us-west-2#edit-item?table=bipsli-${env}-Document&itemMode=2&pk=${recordDetail.Id}&sk&ref=%23item-explorer%3Ftable%3Dbipsli-${env}-Document&route=ROUTE_VIEW_TABLE`;

    return (
        <Wrapper>
            <BackButtonWrapper>
                <BackButton />
            </BackButtonWrapper>
            <div>
                <IdButtonsRow>
                    <IdLabel>
                        {recordDetail.RecordType}
                        {' - '}
                        {recordDetail.Status}
                        <DocNum>
                            #{recordDetail.Id}
                            <Link href={docString} target="_blank">
                                <FaIcon icon={faExternalLinkAlt} />
                            </Link>
                        </DocNum>
                    </IdLabel>
                    <ButtonsBlock>
                        <RetryButton disabled={buttonsDisabled || retryDisabled} onClick={() => onRetry(recordDetail.Id, recordDetail.RecordType, recordDetail.Status)} />
                        <DiscardButton disabled={buttonsDisabled} onClick={() => onDiscard(recordDetail.Id, recordDetail.RecordType, recordDetail.Status)} />
                        <RestartButton disabled={buttonsDisabled} onClick={() => onRestart(recordDetail.Id, recordDetail.RecordType, recordDetail.Status)} />
                    </ButtonsBlock>
                </IdButtonsRow>
                <TimelineWrapper>
                    <RecordTimeline />
                </TimelineWrapper>
                <SummaryRow>{buildSummaryItems(recordDetail).map(({ label, value }) => renderSummaryItem(label, value))}</SummaryRow>
            </div>
        </Wrapper>
    );
};

export default SummaryActions;
