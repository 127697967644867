import * as React from 'react';
import styled from 'styled-components';

import ErrorDetailButton from './ErrorDetailButton';
import { showErrorDetailModal, useAppDispatch } from '../../context';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DocumentError } from '../../models';

interface BannerStyles {
    readonly isOnTop: boolean;
}

const Banner = styled.div<BannerStyles>`
    align-items: center;
    background-color: var(--rose-madder);
    ${({ isOnTop }) => (isOnTop ? 'border-radius: 10px 10px 0 0;' : '')}
    display: grid;
    grid-column-gap: 40px;
    grid-template-columns: 116px 1fr 169px;
    min-height: 60px;
    padding-right: 40px;
`;

const Flag = styled.div`
    position: relative;
    background-color: white;
    color: var(--rose-madder);
    font-family: var(--open-sans-semibold-font);
    font-size: 20px;
    padding-left: 12px;
`;

const FlagCutout = styled.div`
    background-color: var(--rose-madder);
    height: 27.5px;
    position: absolute;
    right: -20px;
    top: 0;
    transform: rotate(45deg);
    width: 27.5px;
`;

const Desc = styled.div`
    color: white;
    font-family: var(--open-sans-semibold-font);
    font-size: 13px;
    text-overflow: ellipsis;
`;

const FaIcon = styled(FontAwesomeIcon)`
    margin: 0 8px;
`;

interface Props {
    errors: DocumentError[];
    isOnTop: boolean;
}

const ErrorBanner: React.FC<Props> = ({ errors, isOnTop }) => {
    const dispatch = useAppDispatch();

    const openErrorDetails = () => {
        dispatch(showErrorDetailModal(errors));
    };

    const renderProcessName = (pName) => {
        return (
            pName && (
                <>
                    {pName} <FaIcon icon={faAngleRight} />
                </>
            )
        );
    };

    return (
        <Banner isOnTop={isOnTop}>
            <Flag>
                {errors.length} Errors
                <FlagCutout />
            </Flag>
            <div>
                {errors.map(({ category, service, errorName, message, processName }, idx) => {
                    return (
                        <Desc key={idx}>
                            {category} <FaIcon icon={faAngleRight} />
                            {service} <FaIcon icon={faAngleRight} />
                            {renderProcessName(processName)}
                            {errorName} <FaIcon icon={faAngleRight} /> {message}{' '}
                        </Desc>
                    );
                })}
            </div>
            <ErrorDetailButton onClick={openErrorDetails} />
        </Banner>
    );
};

export default ErrorBanner;
