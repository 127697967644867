import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { useAppDispatch, closeModal } from '../../context';

const Content = styled.div`
    display: grid;
    grid-row-gap: 23px;
    justify-items: center;
    padding: 40px 60px;
`;

const Text = styled.div`
    color: black;
    font-family: var(--open-sans-semibold-font);
    font-size: 20px;
`;

const Dismiss = styled.div`
    background-color: var(--french-blue);
    color: white;
    cursor: pointer;
    display: flex;
    font-family: var(--open-sans-semibold-font);
    font-size: 16px;
    justify-content: center;
    padding: 14px 55px;
`;

interface Props {
    error?: string;
}

export const Error: React.FC<Props> = ({ error }) => {
    const dispatch = useAppDispatch();

    const onDismiss = () => {
        dispatch(closeModal());
    };
    console.log(error);
    if (!error) {
        error = 'An error has occured. Check log for details.';
    }

    return (
        <div>
            <Content>
                <FontAwesomeIcon icon={faExclamationTriangle} size="3x" color="var(--international-orange)" />
                <Text>{error}</Text>
            </Content>
            <Dismiss onClick={onDismiss}>Dismiss</Dismiss>
        </div>
    );
};
