import * as React from 'react';
import styled from 'styled-components';

interface ButtonStyles {
    readonly disabled: boolean;
}

const Wrapper = styled.div<ButtonStyles>`
    grid-column: 1 / 2;
    color: var(
        ${({ disabled }) =>
            disabled ? '--french-blue-disabled' : '--french-blue'}
    );
    font-size: 12px;
    padding: 10px 0;
    cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'auto')};

    &:hover {
        color: var(
            ${({ disabled }) =>
                disabled ? '--french-blue-disabled' : '--french-blue-hover'}
        );
    }
`;

interface Props {
    disabled: boolean;
    onClick: () => void;
}

const RestartButton: React.FC<Props> = ({ disabled, onClick }) => {
    const handleClick = () => {
        if (disabled) {
            return;
        }
        onClick();
    };

    return (
        <Wrapper disabled={disabled} onClick={handleClick}>
            Restart at the same step
        </Wrapper>
    );
};

export default RestartButton;
