import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { useAppDispatch, closeModal, toggleConfirmDiscard, useAppState, toggleConfirmDiscardAll, toggleCanceledDiscardAll } from '../../context';

const Content = styled.div`
    display: grid;
    grid-row-gap: 23px;
    justify-items: center;
    padding: 40px 60px;
`;

const Text = styled.div`
    color: black;
    font-family: var(--open-sans-semibold-font);
    font-size: 20px;
`;

const Buttons = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
`;

const Cancel = styled.div`
    background-color: var(--platinum);
    color: var(--eerie-black);
    cursor: pointer;
    display: flex;
    font-family: var(--open-sans-semibold-font);
    font-size: 16px;
    justify-content: center;
    padding: 14px 55px;
`;

const Continue = styled.div`
    background-color: var(--french-blue);
    color: white;
    cursor: pointer;
    display: flex;
    font-family: var(--open-sans-semibold-font);
    font-size: 16px;
    justify-content: center;
    padding: 14px 55px;
`;

export const ConfirmDiscard: React.FC = () => {
    const dispatch = useAppDispatch();
    const {
        modal: { recordsLength },
    } = useAppState();

    const onCancel = () => {
        if (recordsLength > 0) dispatch(toggleCanceledDiscardAll());
        dispatch(closeModal());
    };
    const onContinue = () => {
        recordsLength > 0 ? dispatch(toggleConfirmDiscardAll()) : dispatch(toggleConfirmDiscard());
        dispatch(closeModal());
    };
    const recordText = recordsLength > 1 ? ' records.' : ' record.';
    const text = recordsLength > 0 ? 'You are about to discard ' + recordsLength + ' selected ' + recordText : 'You are about to discard this record.';

    return (
        <div>
            <Content>
                <FontAwesomeIcon icon={faExclamationTriangle} size="3x" color="var(--international-orange)" />
                <Text>{text}</Text>
            </Content>
            <Buttons>
                <Cancel onClick={onCancel}>Cancel</Cancel>
                <Continue onClick={onContinue}>Continue</Continue>
            </Buttons>
        </div>
    );
};
