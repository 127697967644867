import * as React from 'react';
import styled from 'styled-components';
import { ColumnData, ColumnSort } from '../../models';
import SortableHeaderRowCell from './SortableHeaderRowCell';

interface TableStyles {
    readonly gridTemplateColumns: string;
}

const Table = styled.div<TableStyles>`
    background-color: var(--platinum);
    border: 1px solid var(--platinum);
    display: grid;
    grid-gap: 1px;
    grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
    height: fit-content;
    overflow: hidden;
`;

const HeaderRowCell = styled.div`
    align-items: center;
    background-color: white;
    color: var(--eerie-black);
    display: flex;
    font-size: 13px;
    height: 50px;
    padding: 0 18px;
`;

const HeaderBorder = styled.div`
    background-color: var(--french-blue);
    grid-column: 1 / -1;
    height: 1px;
`;

interface TableCellStyles {
    readonly index: number;
}

export const TableCell = styled.div<TableCellStyles>`
    align-items: center;
    background-color: ${({ index }) => (index % 2 === 0 ? 'var(--cultured)' : 'white')};
    box-sizing: border-box;
    color: var(--eerie-black);
    display: flex;
    font-size: 13px;
    min-height: 60px;
    padding: 0 18px;
    overflow: hidden;
`;

export const TableCellWrap = styled.div<TableCellStyles>`
    align-items: center;
    background-color: ${({ index }) => (index % 2 === 0 ? 'var(--cultured)' : 'white')};
    box-sizing: border-box;
    color: var(--eerie-black);
    display: flex;
    font-size: 13px;
    min-height: 60px;
    padding: 0 18px;
`;

export const FullRowTableCell = styled(TableCell)`
    grid-column: 1 / -1;
`;

interface Props<C, T> {
    columnData: ColumnData<C>[];
    gridTemplateColumns: string;
    orderBy: ColumnSort;
    renderRow: (item: T, index: number) => React.ReactNode;
    renderNoData: () => React.ReactNode;
    sortBy: C;
    tableData: T[];
    toggleSort: (type: C) => void;
}

export const BaseTable = <C, T>({ columnData, gridTemplateColumns, orderBy, renderRow, renderNoData, sortBy, tableData, toggleSort }: Props<C, T>) => {
    return (
        <Table gridTemplateColumns={gridTemplateColumns}>
            {columnData.map((col) => {
                return col.sortable ? (
                    <SortableHeaderRowCell key={col.label} sorting={sortBy === col.type ? orderBy : ColumnSort.NONE} onClick={() => toggleSort(col.type)}>
                        {col.label}
                    </SortableHeaderRowCell>
                ) : (
                    <HeaderRowCell key={col.label}>{col.label}</HeaderRowCell>
                );
            })}
            <HeaderBorder />
            {tableData.length !== 0 ? tableData.map(renderRow) : renderNoData()}
        </Table>
    );
};
