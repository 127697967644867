export enum RecordErrorsTableColumn {
    NAME = 'NAME',
    ID = 'ID',
    CATEGORY = 'CATEGORY',
    STEP = 'STEP',
    SERVICE = 'SERVICE',
    MESSAGE = 'MESSAGE',
    RECORD_TYPE = 'RECORD_TYPE',
    BUTTONS = 'BUTTONS',
    CHECKBOX = 'CHECKBOX',
}

export enum RecordHistoryTableColumn {
    ACTION = 'ACTION',
    DETAIL = 'DETAIL',
    NAME = 'NAME',
    DATE = 'DATE',
}

export enum SearchResultsTableColumn {
    ID = 'ID',
    RECORD_TYPE = 'RECORD_TYPE',
    DOC_TYPE = 'DOC_TYPE',
    STATUS = 'STATUS',
    CUSTOMER_ID = 'CUSTOMER_ID',
    WEB_ORDER_NUMBER = 'WEB_ORDER_NUMBER',
    FIRST_NAME = 'FIRST_NAME',
    LAST_NAME = 'LAST_NAME',
    INGESTED_DATE = 'INGESTED_DATE',
    CITY = 'CITY',
    STATE = 'STATE',
}

export interface ColumnData<T> {
    label: string;
    type: T;
    sortable: boolean;
    isCheckBox?: boolean;
}

export enum ColumnSort {
    ASC = 'ASC',
    DESC = 'DESC',
    NONE = 'NONE',
}
