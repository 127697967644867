import * as React from 'react';
import styled from 'styled-components';

import { ActiveTabCircle } from '../common';

const CirclesWrapper = styled.div`
    bottom: -9px;
    left: 96px;
    position: absolute;
`;

interface TabStyles {
    readonly isActive: boolean;
}

const Tab = styled.div<TabStyles>`
    align-items: center;
    background-color: var(
        ${({ isActive }) =>
            isActive ? '--french-blue' : '--french-blue-hover'}
    );
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: white;
    cursor: pointer;
    display: flex;
    font-family: var(--open-sans-semibold-font);
    font-size: 16px;
    height: 40px;
    justify-content: center;
    width: 210px;
    position: relative;
`;

interface Props {
    isActive: boolean;
    text: string;
    onClick: () => void;
}

const TabHeader: React.FC<Props> = ({ isActive, text, onClick }) => {
    return (
        <Tab isActive={isActive} onClick={onClick}>
            {isActive ? (
                <CirclesWrapper>
                    <ActiveTabCircle />
                </CirclesWrapper>
            ) : null}
            {text}
        </Tab>
    );
};

export default TabHeader;
