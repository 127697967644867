export default class RequestBuilder {
    private static instance: RequestBuilder;
    private static token: string;

    private constructor() {}

    public static getInstance(): RequestBuilder {
        if (!RequestBuilder.instance) {
            RequestBuilder.instance = new RequestBuilder();
        }

        return RequestBuilder.instance;
    }

    public static setToken(token) {
        RequestBuilder.token = token;
    }

    public get(uri: string) {
        return fetch(uri, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${RequestBuilder.token}`,
            },
        }).then((resp) => {
            if (resp.ok || resp.status === 404) {
                return resp.json();
            }
            throw new Error(`Request failed ${resp.status}`);
        });
    }

    public put(uri: string, body?: Object) {
        return fetch(uri, {
            method: 'PUT',
            body: !!body ? JSON.stringify(body) : undefined,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${RequestBuilder.token}`,
            },
        }).then((resp) => {
            if (resp.ok) {
                if (resp.status === 204) {
                    return;
                }
                return resp.json();
            }
            throw new Error(`Request failed ${resp.status}`);
        });
    }
}
