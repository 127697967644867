import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div`
    align-items: center;
    cursor: pointer;
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 7px;
`;

const Label = styled.div`
    color: var(--eerie-black);
    font-family: var(--roboto-font);
    font-size: 12px;
`;

const BackButton: React.FC = () => {
    const history = useHistory();

    return (
        <Wrapper onClick={() => history.goBack()}>
            <FontAwesomeIcon icon={faChevronLeft} size="xs" />
            <Label>Back</Label>
        </Wrapper>
    );
};

export default BackButton;
