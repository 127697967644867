import * as React from 'react';
import styled from 'styled-components';

import {
    useAppDispatch,
    closeModal,
    toggleConfirmRestart,
} from '../../context';

const Content = styled.div`
    display: grid;
    grid-row-gap: 23px;
    justify-items: center;
    padding: 40px 60px;
`;

const Text = styled.div`
    color: var(--eerie-black);
    font-family: var(--open-sans-font);
    font-size: 16px;
    font-weight: normal;
`;

const Buttons = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
`;

const Cancel = styled.div`
    background-color: var(--platinum);
    color: var(--eerie-black);
    cursor: pointer;
    display: flex;
    font-family: var(--open-sans-semibold-font);
    font-size: 16px;
    justify-content: center;
    padding: 14px 55px;
`;

const Continue = styled.div`
    background-color: var(--french-blue);
    color: white;
    cursor: pointer;
    display: flex;
    font-family: var(--open-sans-semibold-font);
    font-size: 16px;
    justify-content: center;
    padding: 14px 55px;
`;

export const ConfirmRestart: React.FC = () => {
    const dispatch = useAppDispatch();

    const onCancel = () => {
        dispatch(closeModal());
    };
    const onContinue = () => {
        dispatch(toggleConfirmRestart());
        dispatch(closeModal());
    };

    return (
        <div>
            <Content>
                <Text>
                    By choosing to restart at the same step, this will trigger a
                    new BIPSLI run of this document, starting at the last step
                    completed. The previous run will be aborted, and any
                    previous errors marked as resolved.
                    <br />
                    <br />
                    <strong>
                        IMPORTANT: The new run will occur on the latest version
                        of BIPSLI, which may be different than the version of
                        the previous run.
                    </strong>
                </Text>
            </Content>
            <Buttons>
                <Cancel onClick={onCancel}>Cancel</Cancel>
                <Continue onClick={onContinue}>Continue</Continue>
            </Buttons>
        </div>
    );
};
