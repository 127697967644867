import { DashboardPageAction as Action, DashboardPageActionType as ActionType } from './Action';
import { DashboardPageState as State } from './State';
import { ColumnSort } from '../../models';

const checkExhaustive = (_: never): void => {};

export const dashboardPageReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionType.SELECT_DB_TAB: {
            return {
                ...state,
                activeTab: action.tab,
            };
        }
        case ActionType.TOGGLE_FLYOUT_OPEN: {
            return {
                ...state,
                filterFlyout: {
                    ...state.filterFlyout,
                    isOpen: !state.filterFlyout.isOpen,
                },
            };
        }
        case ActionType.RECORD_ERRORS_SORT: {
            const newOrderBy = state.sorting.sortBy !== action.sortBy ? ColumnSort.ASC : state.sorting.orderBy === ColumnSort.ASC ? ColumnSort.DESC : ColumnSort.ASC;
            return {
                ...state,
                sorting: {
                    ...state.sorting,
                    sortBy: action.sortBy,
                    orderBy: newOrderBy,
                },
            };
        }
        case ActionType.SET_ERROR_RECORDS: {
            return {
                ...state,
                recordErrors: [...action.records],
            };
        }
        case ActionType.SET_TOTALS: {
            return {
                ...state,
                totals: {
                    ...state.totals,
                    ingested: action.ingested,
                    succeeded: action.succeeded,
                    errored: action.errored,
                },
            };
        }
        case ActionType.SET_AVERAGES: {
            return {
                ...state,
                averages: {
                    ...state.averages,
                    currentIngested: action.currentIngested,
                    currentSucceeded: action.currentSucceeded,
                    currentErrored: action.currentErrored,
                    previousIngested: action.previousIngested,
                    previousSucceeded: action.previousSucceeded,
                    previousErrored: action.previousErrored,
                    displayPerformance: action.displayPerformance,
                },
            };
        }
        case ActionType.SET_LIVE_STATS: {
            return {
                ...state,
                liveStats: [...action.liveStats],
            };
        }
        case ActionType.REMOVE_ERROR: {
            return {
                ...state,
                recordErrors: [...state.recordErrors.filter((e) => e.documentId !== action.recordId)],
            };
        }
        default:
            checkExhaustive(action);
            return state;
    }
};
