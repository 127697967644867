import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons';

interface ButtonStyles {
    readonly disabled: boolean;
}

const Wrapper = styled.div<ButtonStyles>`
    align-items: center;
    background-color: var(
        ${({ disabled }) =>
            !disabled ? '--french-blue' : '--french-blue-disabled'}
    );
    border-radius: 20px;
    color: white;
    cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'auto')};
    display: grid;
    font-family: var(--open-sans-semibold-font);
    font-size: 16px;
    grid-auto-flow: column;
    grid-column-gap: 5px;
    height: 40px;
    padding: 0 35px;

    &:hover {
        background-color: var(
            ${({ disabled }) =>
                disabled ? '--french-blue-disabled' : '--french-blue-hover'}
        );
    }
`;

interface Props {
    disabled: boolean;
    onClick: () => void;
}

const RetryButton: React.FC<Props> = ({ disabled, onClick }) => {
    const handleClick = () => {
        if (disabled) {
            return;
        }
        onClick();
    };

    const title = disabled
        ? 'Unable to retry. Must be restarted.'
        : 'Retry last step';

    return (
        <Wrapper disabled={disabled} onClick={handleClick} title={title}>
            <FontAwesomeIcon icon={faRedoAlt} size="xs" />
            Retry
        </Wrapper>
    );
};

export default RetryButton;
