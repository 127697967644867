import * as React from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';

import { resetFiltersToDefault, useAppDispatch, useAppState } from '../../context';
import { Header, FilterBadges, FilterSearch, ModalShell } from '../common';
import { useUpdateFilterQuery } from '../common/Hooks';
import FilterFlyout from '../filter-flyout/FilterFlyout';
import SearchResultsTable from '../search-page/SearchResultsTable';
import SearchBadge from '../search-page/SearchBadge';
import { useFetchSearchResults } from '../search-page/Hooks';
import { FilterReset } from '../common/FilterReset';
import { FullPageSpinner } from '../common/FullPageSpinner';
import { initialState } from '../../context/AppState';

const FilterSearchWrapper = styled.div`
    margin-top: 15px;
`;

const Content = styled.div`
    display: grid;
    grid-gap: 20px;
    padding: 20px 40px;
`;

const BadgesRow = styled.div`
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 7fr 1fr 1fr;
`;

const SearchPage: React.FC = () => {
    const { searchFor } = useParams<{ searchFor: string }>();
    let {
        globalFilters: { currentFilters, customFiltersAreApplied },
    } = useAppState();
    const { updateFilterQuery } = useUpdateFilterQuery();
    const { fetchSearchResults } = useFetchSearchResults();
    const dispatch = useAppDispatch();
    const history = useHistory();

    //Reset the currentFilters from History
    React.useEffect(() => {
        if (!customFiltersAreApplied && history.location.pathname.includes('search/')) {
            // eslint-disable-next-line
            currentFilters = initialState.globalFilters.currentFilters;
        }
    }, [customFiltersAreApplied]);

    React.useEffect(updateFilterQuery, [currentFilters]);

    React.useEffect(() => {
        fetchSearchResults(searchFor);
        // eslint-disable-next-line
    }, [searchFor, currentFilters]);

    const onResetClick = () => {
        dispatch(resetFiltersToDefault());
        history.push({
            pathname: `/search`,
            search: window.location.search,
        });
    };

    return (
        <div>
            <FullPageSpinner />
            <Header />
            <FilterSearchWrapper>
                <FilterSearch />
            </FilterSearchWrapper>
            <Content>
                <BadgesRow>
                    <FilterBadges />
                    {!!searchFor ? <SearchBadge searchFor={searchFor} /> : <div></div>}
                    <FilterReset onClick={onResetClick} />
                </BadgesRow>
                <SearchResultsTable />
            </Content>
            <FilterFlyout />
            <ModalShell />
        </div>
    );
};

export default SearchPage;
