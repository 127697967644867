import * as React from 'react';
import { AppState, initialState } from './AppState';
import { appReducer } from './AppReducer';
import { AppDispatch } from './AppDispatch';
import { useParseFilterQuery } from '../components/common/Hooks';
import { RecordStatus, RecordTypes } from '../models';
import moment from 'moment';
import { globalFiltersInitialState } from './global-filter/State';

const AppStateContext = React.createContext<AppState | undefined>(undefined);
const AppDispatchContext = React.createContext<AppDispatch | undefined>(undefined);

interface ProviderProps {
    children?: React.ReactNode;
}

const AppProvider = ({ children }: ProviderProps) => {
    // Update initial state from URL query string
    const { parseFilterQuery } = useParseFilterQuery();
    initialState.globalFilters = parseFilterQuery();

    const [state, dispatch] = React.useReducer(appReducer, initialState);

    return (
        <AppStateContext.Provider value={state}>
            <AppDispatchContext.Provider value={dispatch}>{children}</AppDispatchContext.Provider>
        </AppStateContext.Provider>
    );
};

const useAppState = () => {
    const context = React.useContext(AppStateContext);

    if (context === undefined) {
        throw new Error('useAppState must be used within a AppProvider');
    }

    return context;
};

const useAppDispatch = () => {
    const context = React.useContext(AppDispatchContext);

    if (context === undefined) {
        throw new Error('useAppDispatch must be used within a AppProvider');
    }

    return context;
};

const useCurrentFiltersFromHistory = () => {
    let currentFilters = globalFiltersInitialState.currentFilters;

    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('start') && searchParams.has('end')) {
        const startEpoch = Number.parseInt(searchParams.get('start') ?? '');
        const start = moment(startEpoch);
        const endEpoch = Number.parseInt(searchParams.get('end') ?? '');
        const end = moment(endEpoch);
        if (start.isValid() && end.isValid()) {
            currentFilters.dateRange.start = start;
            currentFilters.dateRange.end = end;
        }
    }
    if (searchParams.has('status')) {
        const statuses = searchParams.getAll('status');
        Object.entries(RecordStatus).forEach(([query, rs]) => {
            if (statuses.includes(query) && !currentFilters.recordStatus.includes(rs)) {
                currentFilters.recordStatus.push(rs);
            }
        });
    }
    if (searchParams.has('type')) {
        const types = searchParams.getAll('type');
        Object.entries(RecordTypes).forEach(([query, rt]) => {
            if (types.includes(query) && !currentFilters.recordTypes.includes(rt)) {
                currentFilters.recordTypes.push(rt);
            }
        });
    }

    return currentFilters;
};

export { AppProvider, useAppState, useAppDispatch, useCurrentFiltersFromHistory };
