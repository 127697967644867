import * as React from 'react';
import styled from 'styled-components';

import { useAppState, useAppDispatch, toggleFlyoutOpen, applyPendingFilters, discardPendingFilterChanges, setFiltersAreApplied } from '../../context';
import FilterContent from './FilterContent';
import { FiltersButton } from '../common';

interface ContainerStyles {
    readonly isOpen: boolean;
    readonly isVisible: boolean;
}

const Container = styled.div<ContainerStyles>`
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 360px 77px;
    height: 100vh;
    left: ${({ isOpen }) => (isOpen ? '0' : '-360px')};
    position: fixed;
    top: 0;
    transition: left 0.3s ease-in;
    visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
    width: 437px;
    z-index: 5;
`;

const ContentSection = styled.div`
    background-color: white;
    border-right: 5px solid var(--french-blue);
    box-sizing: border-box;
    overflow-y: auto;
    padding: 30px 30px 110px 30px;
    position: relative;
`;

const FiltersWrapper = styled.div`
    margin-top: 65px;
`;

interface ButtonStyles {
    readonly disabled: boolean;
}
const ButtonContainer = styled.div`
    box-shadow: var(--sortof-gray) -1px -1px 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 30px 30px 30px 20px;
    background-color: white;
    width: 305px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
`;
const ApplyButton = styled.div<ButtonStyles>`
    align-items: center;
    background-color: var(${({ disabled }) => (!disabled ? '--french-blue' : '--french-blue-disabled')});
    border-radius: 5px;
    color: white;
    cursor: pointer;
    display: grid;
    font-family: var(--open-sans-font);
    font-size: 16px;
    grid-auto-flow: column;
    grid-column-gap: 5px;
    padding: 10px 20px;
    width: 100px;
    text-align: center;
    &:hover {
        background-color: var(${({ disabled }) => (disabled ? '--french-blue-disabled' : '--french-blue-hover')});
    }
`;

const CancelButton = styled.div<ButtonStyles>`
    align-items: center;
    border: 2px solid var(--french-blue);
    border-color: var(${({ disabled }) => (!disabled ? '--french-blue' : '--french-blue-disabled')});
    border-radius: 5px;
    color: var(${({ disabled }) => (!disabled ? '--french-blue' : '--french-blue-disabled')});
    cursor: pointer;
    display: grid;
    font-family: var(--open-sans-font);
    font-size: 16px;
    grid-auto-flow: column;
    grid-column-gap: 5px;
    padding: 10px 20px;
    width: 100px;
    text-align: center;

    &:hover {
        color: var(${({ disabled }) => (disabled ? '--french-blue-disabled' : '--french-blue-hover')});
    }
`;

const DirtyWarning = styled.div`
    text-align: center;
    font-size: 12px;
    color: var(--somewhat-gray);
    grid-column: 1 / span 2;
    position: absolute;
    top: 5px;
    &:after {
        position: absolute;
        background-color: var(--rose-madder);
        height: 6px;
        width: 6px;
        border-radius: 6px;
        left: -10px;
        top: 50%;
        transform: translateY(-50%);
        content: ' ';
    }
`;

const FilterFlyout: React.FC = () => {
    const dispatch = useAppDispatch();
    const [isVisible, setIsVisible] = React.useState(false);
    const {
        globalFilters: { filtersAreChangedButUnapplied, dateRangeGreaterThanThirtyDays },
    } = useAppState();
    const {
        dashboardPage: {
            filterFlyout: { isOpen },
        },
    } = useAppState();

    const closeFlyout = () => {
        dispatch(toggleFlyoutOpen());
    };

    const applyFilters = () => {
        if (!filtersAreChangedButUnapplied || dateRangeGreaterThanThirtyDays) return;
        dispatch(applyPendingFilters());
        dispatch(setFiltersAreApplied(true));
        closeFlyout();
    };

    const discardFilterChanges = () => {
        if (!filtersAreChangedButUnapplied) return;
        dispatch(discardPendingFilterChanges());
        closeFlyout();
    };

    React.useEffect(() => {
        if (isOpen) {
            setIsVisible(true);
        }
    }, [isOpen]);

    const transitionEnd = () => {
        if (!isOpen) {
            setIsVisible(false);
        }
    };
    const renderDirtyWarning = () => {
        return filtersAreChangedButUnapplied && <DirtyWarning>You have unapplied filter changes</DirtyWarning>;
    };

    return (
        <Container isOpen={isOpen} onTransitionEnd={transitionEnd} isVisible={isVisible}>
            <ContentSection>
                <FilterContent />
            </ContentSection>
            <ButtonContainer>
                {renderDirtyWarning()}
                <CancelButton disabled={!filtersAreChangedButUnapplied || dateRangeGreaterThanThirtyDays} onClick={discardFilterChanges}>
                    Discard
                </CancelButton>
                <ApplyButton disabled={!filtersAreChangedButUnapplied || dateRangeGreaterThanThirtyDays} onClick={applyFilters}>
                    Apply Filters
                </ApplyButton>
            </ButtonContainer>
            <FiltersWrapper>
                <FiltersButton isOpen={true} onClick={closeFlyout} />
            </FiltersWrapper>
        </Container>
    );
};

export default FilterFlyout;
