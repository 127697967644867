import { RecordDetailPageState as State } from './State';
import {
    RecordDetailPageAction as Action,
    RecordDetailPageActionType as ActionType,
} from './Action';
import { blankRecord } from '../../models';

const checkExhaustive = (_: never): void => {};

export const recordDetailPageReducer = (
    state: State,
    action: Action
): State => {
    switch (action.type) {
        case ActionType.SET_RECORD: {
            return {
                ...state,
                recordDetail: {
                    ...action.record,
                },
            };
        }
        case ActionType.SET_EDIT_RECORD: {
            return {
                ...state,
                editedRecord: {
                    ...state.editedRecord,
                    ...action.record,
                },
            };
        }
        case ActionType.REVERT_FIELD: {
            return {
                ...state,
                editedRecord: {
                    ...state.editedRecord,
                    [action.field]: state.recordDetail[action.field],
                },
            };
        }
        case ActionType.TOGGLE_ORIG_RECORD: {
            return {
                ...state,
                showOriginalRecord: !state.showOriginalRecord,
            };
        }
        case ActionType.CLEAR_EDITING_FIELDS: {
            return {
                ...state,
                editingFields: [],
            };
        }
        case ActionType.TOGGLE_EDITING_FIELD: {
            const includesItem = state.editingFields.includes(action.field);
            return {
                ...state,
                editingFields: includesItem
                    ? state.editingFields.filter((f) => f !== action.field)
                    : [...state.editingFields, action.field],
            };
        }
        case ActionType.CLEAR_RECORDS: {
            return {
                ...state,
                recordDetail: { ...blankRecord },
                editedRecord: { ...blankRecord },
            };
        }
        default:
            checkExhaustive(action);
            return state;
    }
};
