import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockOpen } from '@fortawesome/free-solid-svg-icons';

const Wrapper = styled.div`
    align-items: center;
    cursor: pointer;
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 7px;
    background-color: var(--french-blue);
    border-radius: 15px;
    color: white;
    font-family: var(--open-sans-semibold);
    font-size: 16px;
    height: 30px;
    line-height: 30px;
    padding: 0 20px;
    white-space: nowrap;
    width: 72px;

    &:hover {
        background-color: var(--french-blue-hover);
    }
`;

const Label = styled.div`
    font-family: var(--roboto-font);
    font-size: 12px;
`;

const DecryptButton: React.FC<any> = ({ onClick, ccNum, visible }) => {
    const render = () => {
        if (visible) {
            return ccNum ? (
                <>{ccNum}</>
            ) : (
                <Wrapper onClick={() => onClick()}>
                    <FontAwesomeIcon icon={faLockOpen} size="xs" />
                    <Label>Get Value</Label>
                </Wrapper>
            );
        }
        return <></>;
    };
    return render();
};

export default DecryptButton;
