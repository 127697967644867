import * as React from 'react';
import { SnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const CheckIcon: React.FC = () => {
    return (
        <FontAwesomeIcon
            icon={faCheck}
            size="2x"
            color="#9ce03e"
            style={{ marginRight: '15px' }}
        />
    );
};

interface Props {
    children: React.ReactNode | React.ReactNode[];
}

export const CustomSnackbarProvider: React.FC<Props> = ({ children }) => {
    const useStyles = makeStyles((theme) => ({
        success: {
            backgroundColor: 'white !important',
            border: '1px solid #9ce03e',
            color: 'black !important',
        },
    }));

    const classes = useStyles();

    return (
        <SnackbarProvider
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            maxSnack={3}
            classes={{
                variantSuccess: classes.success,
            }}
            iconVariant={{
                success: <CheckIcon />,
            }}
        >
            {children}
        </SnackbarProvider>
    );
};
