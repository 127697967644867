import * as React from 'react';
import styled from 'styled-components';
import { UnstyledLink, BadgeColor, Badge } from '../common';
import { environment } from '../../environment';
// import { ReactComponent as NautilusLogo } from '../../images/NLSInc-BlueBlack.svg';
import { ReactComponent as BowflexLogo } from '../../images/BowflexLogo.svg';
import { useReactOidc } from '@axa-fr/react-oidc-context';

const HeaderContent = styled.div`
    align-items: center;
    background-color: var(--french-blue);
    box-sizing: border-box;
    color: white;
    display: grid;
    font-family: var(--open-sans-bold-font);
    font-size: 20px;
    grid-template-columns: 110px 60px 0fr 1fr;
    grid-column-gap: 20px;
    height: 50px;
    justify-content: start;
    letter-spacing: 2px;
    padding: 0 40px;
    width: 100%;
`;

const Username = styled.div`
    text-align: right;
    font-size: 12px;
    font-family: var(--open-sans-font);
`;

export const Header: React.FC = () => {
    const { oidcUser } = useReactOidc();
    const { profile } = oidcUser;
    const showEnvironment: boolean = environment.envName?.toLowerCase() !== 'production';

    return (
        <HeaderContent>
            <BowflexLogo width="110px" />
            <UnstyledLink to="/">BIPSLI</UnstyledLink>
            {showEnvironment ? (
                <Badge showCloseButton={false} bgColor={BadgeColor.Red}>
                    {environment.envName?.toUpperCase()}
                </Badge>
            ) : (
                <span></span>
            )}
            <Username>{profile.email}</Username>
        </HeaderContent>
    );
};
