export enum RecordTypes {
    EmailOnly = 'EmailOnly',
    Lead = 'Lead',
    Order = 'Order',
    Quote = 'Quote',
    OptOut = 'OptOut',
    Subscription = 'Subscription',
    CancelOrder = 'CancelOrder',
    Membership = 'Membership',
}
