import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    font-size: 2em;
`;

export const Authenticating: React.FC = () => {
    return <Wrapper>Logging in...</Wrapper>;
};
