import * as React from 'react';
import styled from 'styled-components';
import { DateRange, Range } from 'react-date-range';

import { useAppState, useAppDispatch, setStartDate, setEndDate, setDateRange } from '../../context';
import { TimePicker } from '../common';
import { addDays, subMonths } from 'date-fns';

const Wrapper = styled.div`
    display: grid;
    grid-row-gap: 10px;
`;

const TimeRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 8.33px;
`;

interface SelectionChange {
    selection?: {
        startDate: Date;
        endDate: Date;
    };
}

const DateRangeOptions: React.FC = () => {
    const {
        globalFilters: {
            pendingFilters: { dateRange },
        },
    } = useAppState();
    const dispatch = useAppDispatch();

    let startDate = React.useState(new Date())[0];
    let endDate = React.useState(new Date())[0];

    const dateDiff = (startDate: Date, endDate: Date) => {
        return Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
    };

    const handleChange = (range: Range) => {
        // The definition for this function's argument types
        // is wrong, this is the correct type
        const value = range as SelectionChange;
        if (value.selection) {
            startDate = value.selection.startDate;
            endDate = value.selection.endDate;
            dispatch(setDateRange(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), dateRange.start.hour(), endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), dateRange.end.hour(), dateDiff(startDate, endDate)));
        }
    };
    const handleStartTimeChange = (hour: number) => {
        dispatch(setStartDate(dateRange.start.year(), dateRange.start.month(), dateRange.start.date(), hour));
    };
    const handleEndTimeChange = (hour: number) => {
        dispatch(setEndDate(dateRange.end.year(), dateRange.end.month(), dateRange.end.date(), hour, dateDiff(startDate, endDate)));
    };

    const selectionRange = {
        startDate: dateRange.start.toDate(),
        endDate: dateRange.end.toDate(),
        key: 'selection',
    };

    return (
        <Wrapper>
            <DateRange
                ranges={[selectionRange]}
                onChange={handleChange}
                dateDisplayFormat="MM/dd/yyyy"
                rangeColors={['#3eef8f']}
                // @ts-ignore
                fixedHeight={true}
                minDate={subMonths(new Date(), 6)}
                maxDate={addDays(new Date(new Date().toISOString().split('.')[0]), 0)}
            />
            <TimeRow>
                <TimePicker value={dateRange.start.hour()} onChange={handleStartTimeChange} />
                <TimePicker value={dateRange.end.hour()} onChange={handleEndTimeChange} />
            </TimeRow>
        </Wrapper>
    );
};

export default DateRangeOptions;
