import React from 'react';
import './App.css';
import 'react-date-range/dist/styles.css'; // main style file
import './calendar/calendarTheme.css'; // customized calendar theme
import { AuthenticationProvider, oidcLog } from '@axa-fr/react-oidc-context';
import AppRoutes from './AppRoutes';
import { Authenticating } from './components/auth/Authenticating';
import { NotAuthorized } from './components/auth/NotAuthorized';
import { Authenticated } from './components/auth/Authenticated';
import { environment } from './environment';

function App() {
    const oidcConfiguration = {
        response_type: 'code',
        scope: 'openid profile',
        authority: environment.authority,
        client_id: environment.clientId,
        redirect_uri: `${window.location.origin}/authentication/callback`,
        silent_redirect_uri: `${window.location.origin}/authentication/silent_callback`,
        automaticSilentRenew: true,
    };

    return (
        <div className="App">
            <AuthenticationProvider
                authenticating={Authenticating}
                notAuthenticated={NotAuthorized}
                notAuthorized={NotAuthorized}
                configuration={oidcConfiguration}
                callbackComponentOverride={Authenticated}
                loggerLevel={oidcLog.DEBUG}
                isEnabled={true}
            >
                <AppRoutes />
            </AuthenticationProvider>
        </div>
    );
}

export default App;
