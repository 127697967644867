import { ColumnSort, DashboardTabs, LiveStat, RecordErrorsTableColumn, RecordTypes } from '../../models';
import { SearchResultDoc } from '../../models/response';

export interface DashboardPageState {
    activeTab: DashboardTabs;
    filterFlyout: {
        isOpen: boolean;
    };
    recordErrors: SearchResultDoc[];
    sorting: {
        sortBy: RecordErrorsTableColumn;
        orderBy: ColumnSort;
    };
    totals: {
        ingested: number;
        succeeded: number;
        errored: number;
    };
    averages: {
        currentIngested: number;
        currentSucceeded: number;
        currentErrored: number;
        previousIngested: number;
        previousSucceeded: number;
        previousErrored: number;
        displayPerformance: boolean;
    };
    liveStats: LiveStat[];
}

export const dashboardPageInitialState: DashboardPageState = {
    activeTab: DashboardTabs.ERRORS,
    filterFlyout: {
        isOpen: false,
    },
    sorting: {
        sortBy: RecordErrorsTableColumn.ID,
        orderBy: ColumnSort.ASC,
    },
    recordErrors: [],
    totals: {
        ingested: 0,
        succeeded: 0,
        errored: 0,
    },
    averages: {
        currentIngested: 0,
        currentSucceeded: 0,
        currentErrored: 0,
        previousIngested: 0,
        previousSucceeded: 0,
        previousErrored: 0,
        displayPerformance: false,
    },
    liveStats: [
        {
            docsPerMinute: 0,
            recordType: RecordTypes.EmailOnly,
            recordsInProcess: 0,
            recordsInError: 0,
        },
        {
            docsPerMinute: 0,
            recordType: RecordTypes.Order,
            recordsInProcess: 0,
            recordsInError: 0,
        },
        {
            docsPerMinute: 0,
            recordType: RecordTypes.Quote,
            recordsInProcess: 0,
            recordsInError: 0,
        },
        {
            docsPerMinute: 0,
            recordType: RecordTypes.Lead,
            recordsInProcess: 0,
            recordsInError: 0,
        },
        {
            docsPerMinute: 0,
            recordType: RecordTypes.OptOut,
            recordsInProcess: 0,
            recordsInError: 0,
        },
        {
            docsPerMinute: 0,
            recordType: RecordTypes.Subscription,
            recordsInProcess: 0,
            recordsInError: 0,
        },
        {
            docsPerMinute: 0,
            recordType: RecordTypes.CancelOrder,
            recordsInProcess: 0,
            recordsInError: 0,
        },
        {
            docsPerMinute: 0,
            recordType: RecordTypes.Membership,
            recordsInProcess: 0,
            recordsInError: 0,
        },
    ],
};
