import * as React from 'react';
import styled from 'styled-components';

import { Checkbox } from '../common';
import { useAppDispatch, useAppState, filterByRecordType, filterByRecordStatus, toggleRecordStatusAll, toggleRecordTypeAll, toggleDateFilterMode } from '../../context';
import { RecordTypes, RecordStatus } from '../../models';
import DateRangeOptions from './DateRangeOptions';
import { StateToggle } from '../common/StateToggle';
import { DateFilterMode } from '../../models/DateFilterMode';

const Wrapper = styled.div`
    display: grid;
    grid-row-gap: 29px;
`;

const CheckAllLink = styled.div`
    cursor: pointer;
    color: var(--french-blue);
    font-size: 12px;
    margin-top: 10px;
    &:hover {
        color: var(--french-blue-hover);
    }
`;

const Label = styled.div`
    color: var(--eerie-black);
    font-family: var(--open-sans-bold-font);
    font-size: 13px;
    margin-bottom: 10px;
`;

const DateRangeLabel = styled.div`
    background-color: var(--international-orange);
    border-radius: 15px;
    color: white;
    font-family: var(--open-sans-bold-font);
    font-size: 11px;
    margin-bottom: 10px;
    padding: 0 20px;
`;

const OptionSet = styled.div`
    display: grid;
    grid-row-gap: 9px;
`;

const DateFilterLabel = styled.div`
    color: var(--sortof-gray);
    font-family: var(--open-sans-bold-font);
    font-size: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
`;

const FilterContent: React.FC = () => {
    const dispatch = useAppDispatch();
    const { globalFilters } = useAppState();

    const onSelectStatus = (status: RecordStatus) => {
        dispatch(filterByRecordStatus(status));
    };
    const onToggleStatusAll = () => {
        dispatch(toggleRecordStatusAll());
    };
    const onSelectRecordType = (type: RecordTypes) => {
        dispatch(filterByRecordType(type));
    };
    const onToggleRecordTypeAll = () => {
        dispatch(toggleRecordTypeAll());
    };
    const onToggleDateFilterMode = () => {
        dispatch(toggleDateFilterMode());
    };

    return (
        <Wrapper>
            <div>
                <Label>Date Range</Label>
                <DateRangeOptions />
            </div>
            <div>
                <DateRangeLabel>Maximum selectable date range is 30 days</DateRangeLabel>
                <Label>Date used for Range Filter</Label>
                <StateToggle active={globalFilters.pendingFilters.dateFilterMode === DateFilterMode.IngestDate} onLabel="Ingest Date" offLabel="Last Transition" onColor="--french-blue" offColor="--french-blue" onClick={onToggleDateFilterMode}></StateToggle>
                <DateFilterLabel>All records {globalFilters.pendingFilters.dateFilterMode === DateFilterMode.IngestDate ? ' ingested ' : ' that did something '} during this time</DateFilterLabel>
            </div>
            <div>
                <Label>Record Type</Label>
                <OptionSet>
                    {Object.values(RecordTypes).map((type) => {
                        return <Checkbox key={type} label={type} isChecked={globalFilters.pendingFilters.recordTypes.includes(type)} onClick={() => onSelectRecordType(type)} />;
                    })}
                </OptionSet>
                <CheckAllLink onClick={onToggleRecordTypeAll}>Check All</CheckAllLink>
            </div>
            <div>
                <Label>Status</Label>
                <OptionSet>
                    {Object.values(RecordStatus).map((status) => {
                        return <Checkbox key={status} label={status} isChecked={globalFilters.pendingFilters.recordStatus.includes(status)} onClick={() => onSelectStatus(status)} />;
                    })}
                </OptionSet>
                <CheckAllLink onClick={onToggleStatusAll}>Check All</CheckAllLink>
            </div>
        </Wrapper>
    );
};

export default FilterContent;
