import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    padding: 20px 0 0 0;
    text-align: right;
`;

const ResetLink = styled.div`
    color: var(--french-blue);
    font-size: 12px;
    padding: 10px 0;
    cursor: pointer;
    display: inline-block;
    padding: 5px;

    &:hover {
        color: var(--french-blue-hover);
    }
`;

interface Props {
    onClick: () => void;
}

export const FilterReset: React.FC<Props> = ({ onClick }) => {
    return (
        <Wrapper>
            <ResetLink onClick={onClick}>Reset Filters</ResetLink>
        </Wrapper>
    );
};
