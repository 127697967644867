import { disableSpinner, enableSpinner, setEditedRecord, setRecordDetail, showErrorModal, useAppDispatch, useAppState } from '../../context';
import { RecordsSvc } from '../../services';
import { BipsliDocument, blankRecord } from '../../models';
import { DecryptPaymentInfoRequest } from '../../models/request/DecryptPaymentInfoRequest';

export const useFetchRecordDetail = () => {
    const dispatch = useAppDispatch();

    const fetchRecordDetail = (id: string, editedDoc?: BipsliDocument) => {
        const getRecordDetail = async () => {
            dispatch(enableSpinner());
            const data = await RecordsSvc.getRecordDetail(id).catch((e) => {
                console.error('Error fetching record details', e);
                dispatch(showErrorModal());
            });

            if (data) {
                // Decunstruct the pieces we need
                const { document, errors, history } = data;

                // Setup base document structure and main properties
                let record = { ...blankRecord, ...document } as BipsliDocument;
                record.Errors = errors || [];
                record.History = history || [];
                record.ErrorDate = errors === null || errors.length === 0 ? '' : errors[0].timestamp;

                // For now, we must pull these items out in preference of Ingestor 2 -> Ingestor 2 -> null
                record.LineItems = document.LineItems ?? document.Order?.OrderLines ?? document.Quote?.OrderLines ?? null;
                record.OrderPayments = document.OrderPayments ?? document.Order?.Payments ?? document.Quote?.Payments ?? null;

                dispatch(setRecordDetail(record));
                dispatch(setEditedRecord(editedDoc ? editedDoc : record));
            }
            dispatch(disableSpinner());
        };

        getRecordDetail();
    };

    return {
        fetchRecordDetail,
    };
};

export const useGetDecryptedPaymentInfo = () => {
    const dispatch = useAppDispatch();
    const {
        recordDetailPage: { editedRecord },
    } = useAppState();

    const getDecryptedPaymentInfo = (paymentInfo: string, ingestorVersion: string, isCreditCard: boolean = true) => {
        const getDecryptedPaymentInfoAsync = async () => {
            dispatch(enableSpinner());
            const req: DecryptPaymentInfoRequest = {
                PaymentInfo: paymentInfo,
                IngestorVersion: ingestorVersion,
            };
            let data;
            if (isCreditCard) {
                data = await RecordsSvc.getDecryptedCCNumber(req).catch((e) => {
                    console.error('Error fetching decryption results', e);
                    dispatch(showErrorModal());
                });
            } else {
                data = await RecordsSvc.getDecryptedAccountNumber(req).catch((e) => {
                    console.error('Error fetching decryption results', e);
                    dispatch(showErrorModal());
                });
            }
            if (data) {
                const payment = editedRecord.OrderPayments.find((p) => p.CCNumber === paymentInfo);
                payment.CCNumber = data.plainTextPaymentInfo;
                payment.Decrypted = true;
                dispatch(setEditedRecord(editedRecord));
            }
            dispatch(disableSpinner());
        };

        getDecryptedPaymentInfoAsync();
    };

    return {
        getDecryptedPaymentInfo,
    };
};
