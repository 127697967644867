import { AppAction } from '../AppAction';
import { AdminPageActionType as ActionType } from './Action';
import { IngestorV2State } from '../../models/IngestorState';

export const getIngestorStatus = (): AppAction => ({
    type: ActionType.GET_INGESTOR_STATUS,
});

export const setIngestorV2Status = (ingestorV2Status: IngestorV2State[]): AppAction => ({
    type: ActionType.SET_INGESTOR_V2_STATUS,
    ingestorV2Status,
});
