import { AppAction } from '../AppAction';
import { SearchPageActionType as ActionType } from './Action';
import { SearchResultsTableColumn } from '../../models';
import { SearchResponse } from '../../models/response/SearchResponse';

export const setSearchResults = (searchResults: SearchResponse): AppAction => ({
    type: ActionType.SET_SEARCH_RESULTS,
    searchResults,
});

export const sortSearchResults = (sortBy: SearchResultsTableColumn): AppAction => ({
    type: ActionType.SEARCH_RESULTS_SORT,
    sortBy,
});
