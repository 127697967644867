import * as R from 'ramda';

import { useAppState, useAppDispatch, setSearchResults, disableSpinner, enableSpinner, showErrorModal } from '../../context';
import { ColumnSort, FilterQueryParams, SearchResultsTableColumn } from '../../models';
import { SearchResultDoc } from '../../models/response';
import { RecordsSvc } from '../../services';

const buildSortDirection = (orderBy: ColumnSort) => {
    return orderBy === ColumnSort.DESC ? R.descend : R.ascend;
};

export const useSortedSearchResults = (): {
    sortedSearchResults: SearchResultDoc[];
} => {
    const {
        searchPage: { searchResults, sorting },
    } = useAppState();

    let sortedSearchResults = [...searchResults];
    if (sortedSearchResults.length <= 1 || sorting.orderBy === ColumnSort.NONE) {
        return { sortedSearchResults };
    }

    const sortDirection = buildSortDirection(sorting.orderBy);

    switch (sorting.sortBy) {
        case SearchResultsTableColumn.ID: {
            const byId = (r: SearchResultDoc): string => R.prop('documentId', r);
            sortedSearchResults = R.sort(sortDirection(byId), sortedSearchResults);
            break;
        }
        case SearchResultsTableColumn.RECORD_TYPE: {
            const byRecordType = (r: SearchResultDoc): string => R.prop('recordType', r);
            sortedSearchResults = R.sort(sortDirection(byRecordType), sortedSearchResults);
            break;
        }
        case SearchResultsTableColumn.DOC_TYPE: {
            const byDocType = (r: SearchResultDoc): string => R.prop('docType', r);
            sortedSearchResults = R.sort(sortDirection(byDocType), sortedSearchResults);
            break;
        }
        case SearchResultsTableColumn.STATUS: {
            const byStatus = (r: SearchResultDoc): string => R.prop('status', r);
            sortedSearchResults = R.sort(sortDirection(byStatus), sortedSearchResults);
            break;
        }
        case SearchResultsTableColumn.CUSTOMER_ID: {
            const byCustomerId = (r: SearchResultDoc): string => R.prop('customerNumber', r);
            sortedSearchResults = R.sort(sortDirection(byCustomerId), sortedSearchResults);
            break;
        }
        case SearchResultsTableColumn.FIRST_NAME: {
            const byFirstName = (r: SearchResultDoc): string => R.prop('firstName', r);
            sortedSearchResults = R.sort(sortDirection(byFirstName), sortedSearchResults);
            break;
        }
        case SearchResultsTableColumn.LAST_NAME: {
            const byLastName = (r: SearchResultDoc): string => R.prop('lastName', r);
            sortedSearchResults = R.sort(sortDirection(byLastName), sortedSearchResults);
            break;
        }
        case SearchResultsTableColumn.CITY: {
            const byCity = (r: SearchResultDoc): string => R.prop('city', r);
            sortedSearchResults = R.sort(sortDirection(byCity), sortedSearchResults);
            break;
        }
        case SearchResultsTableColumn.STATE: {
            const byState = (r: SearchResultDoc): string => R.prop('state', r);
            sortedSearchResults = R.sort(sortDirection(byState), sortedSearchResults);
            break;
        }
    }

    return { sortedSearchResults };
};

export const useFetchSearchResults = () => {
    const dispatch = useAppDispatch();
    const { globalFilters } = useAppState();

    const fetchSearchResults = (searchFor: string) => {
        const fetchSearchResultsAsync = async () => {
            dispatch(enableSpinner());
            const params: FilterQueryParams = {
                start: globalFilters.currentFilters.dateRange.start,
                end: globalFilters.currentFilters.dateRange.end,
                recordTypes: globalFilters.currentFilters.recordTypes,
                statuses: globalFilters.currentFilters.recordStatus,
                dateFilterMode: globalFilters.currentFilters.dateFilterMode,
            };
            const data = await RecordsSvc.getSearchRecords(params, searchFor).catch((e) => {
                console.error('Error fetching search results', e);
                dispatch(showErrorModal());
            });
            if (data) {
                dispatch(setSearchResults(data));
            }
            dispatch(disableSpinner());
        };

        fetchSearchResultsAsync();
    };

    return {
        fetchSearchResults,
    };
};
