import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

import { useAppDispatch, closeModal } from '../../context';
import { formatDate } from '../common';
import Link from '@material-ui/core/Link';
import { environment } from '../../environment';
import { DocumentError } from '../../models';

const Content = styled.div`
    padding: 40px 60px;
    font-size: 12px;
    min-width: 500px;
`;

const Detail = styled.div`
    margin-bottom: 20px;
`;

const DetailRow = styled.div`
    text-align: left;
    display: grid;
    grid-template-columns: 25% 75%;
    border-bottom: 1px solid var(--barely-gray);
    padding-bottom: 5px;
    margin-bottom: 5px;
`;
const DetailHeader = styled.div`
    font-weight: bold;
`;

const BulletRow = styled.div`
    display: flex;
    margin: 10px;
    justify-content: space-evenly;
    margin: 30px 40% 10px;
    font-size: 8px;
    color: var(--barely-gray);
`;

const Dismiss = styled.div`
    background-color: var(--french-blue);
    color: white;
    cursor: pointer;
    display: flex;
    font-family: var(--open-sans-semibold-font);
    font-size: 16px;
    justify-content: center;
    padding: 14px 55px;
`;

const StackTrace = styled.div`
    border: 1px solid var(--sortof-gray);
    border-radius: 3px;
    background-color: var(--barely-gray);
    max-height: 200px;
    color: var(--somewhat-gray);
    overflow: auto;
    padding: 10px;
    font-family: monospace;
    margin: 10px 0;

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 10px;
    }
`;
interface Props {
    errors: DocumentError[];
}
const ENV_NAME = environment.envName;

export const ErrorDetails: React.FC<Props> = ({ errors }) => {
    const dispatch = useAppDispatch();
    const execString = 'https://us-west-2.console.aws.amazon.com/states/home?region=us-west-2#/executions/details/';
    const renderErrors = () => {
        return errors.map((error, idx) => renderErrorDetail(error, idx));
    };

    const renderProcessName = (pName) => {
        return (
            pName && (
                <DetailRow>
                    <DetailHeader>Process Name:</DetailHeader>
                    <div>{pName}</div>
                </DetailRow>
            )
        );
    };
    const renderExecLink = (arn, title) => {
        return (
            arn && (
                <DetailRow>
                    <DetailHeader>{title}:</DetailHeader>
                    <Link href={`${execString}${arn}`} target="_blank">
                        Go to execution <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </Link>
                </DetailRow>
            )
        );
    };
    const renderStackTrace = (trace) => {
        return (
            trace &&
            trace.length > 0 && (
                <>
                    <DetailHeader>Stacktrace:</DetailHeader>
                    <StackTrace>{trace}</StackTrace>
                </>
            )
        );
    };
    const renderOtherDetails = (otherDetails) => {
        return (
            otherDetails &&
            Object.keys(otherDetails).length > 0 && (
                <>
                    <DetailHeader>Other Details:</DetailHeader>
                    <StackTrace>
                        <pre>{JSON.stringify(otherDetails, null, 2)}</pre>
                    </StackTrace>
                </>
            )
        );
    };
    const renderBulletRow = (idx) => {
        return (
            errors.length > 1 &&
            idx < errors.length - 1 && (
                <BulletRow>
                    <FontAwesomeIcon icon={faCircle} />
                    <FontAwesomeIcon icon={faCircle} />
                    <FontAwesomeIcon icon={faCircle} />
                </BulletRow>
            )
        );
    };

    const renderErrorDetail = (error: DocumentError, idx) => {
        let env = ENV_NAME.charAt(0).toUpperCase() + ENV_NAME.slice(1);
        const docString = `https://us-west-2.console.aws.amazon.com/dynamodbv2/home?region=us-west-2#edit-item?table=bipsli-${env}-StepError&itemMode=2&pk=${error.id}&ref=%23table%3Fname%3Dbipsli-${env}-StepError&route=ROUTE_VIEW_TABLE`;
        return (
            <Detail key={error.id}>
                <DetailRow>
                    <DetailHeader>Category:</DetailHeader>
                    <div>{error.category}</div>
                </DetailRow>
                <DetailRow>
                    <DetailHeader>Service:</DetailHeader>
                    <div>{error.service}</div>
                </DetailRow>
                {renderProcessName(error.processName)}
                <DetailRow>
                    <DetailHeader>Error Name:</DetailHeader>
                    <div>{error.errorName}</div>
                </DetailRow>
                <DetailRow>
                    <DetailHeader>Message:</DetailHeader>
                    <div>{error.message}</div>
                </DetailRow>
                <DetailRow>
                    <DetailHeader>Timestamp:</DetailHeader>
                    <div>{formatDate(error.timestamp)}</div>
                </DetailRow>
                <DetailRow>
                    <DetailHeader>AWS Error Doc:</DetailHeader>
                    <Link href={docString} target="_blank">
                        Open dynamo document <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </Link>
                </DetailRow>
                {renderExecLink(error.executionArn, 'AWS Execution')}
                {renderExecLink(error.childExecutionArn, 'AWS Child Execution')}
                {renderStackTrace(error.stackTrace)}
                {renderOtherDetails(error.resultMetadata)}
                {renderBulletRow(idx)}
            </Detail>
        );
    };

    const onDismiss = () => {
        dispatch(closeModal());
    };

    return (
        <div>
            <Content>{renderErrors()}</Content>
            <Dismiss onClick={onDismiss}>Dismiss</Dismiss>
        </div>
    );
};
