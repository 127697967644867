import * as React from 'react';
import styled from 'styled-components';
import { Header, ModalShell } from '../common';
import { useFetchIngestorStatus } from '../admin-page/Hooks';
import { FullPageSpinner } from '../common/FullPageSpinner';
import IngestorStatusV2Widget from '../admin-page/IngestorStatusV2Widget';

const Wrapper = styled.div`
    background-color: white;
    padding: 20px;
`;

const Widgets = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
`;

const Blurb = styled.div`
    margin: 5px 0 10px 0;
    font-size: 14px;
`;

const AdminPage: React.FC = () => {
    const { fetchIngestorStatus } = useFetchIngestorStatus();

    React.useEffect(() => {
        fetchIngestorStatus();
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <FullPageSpinner />
            <Header />
            <Wrapper>
                <Blurb>Use the tools below to turn ingestors on or off, or to change the amount of documents they will pull for each record type.</Blurb>
                <Widgets>
                    <IngestorStatusV2Widget />
                    <div></div>
                </Widgets>
            </Wrapper>
            <ModalShell />
        </div>
    );
};

export default AdminPage;
