import { IngestorV2State } from '../../models/IngestorState';

export enum AdminPageActionType {
    GET_INGESTOR_STATUS = 'GET_INGESTOR_STATUS',
    SET_INGESTOR_STATUS = 'SET_INGESTOR_STATUS',
    SET_INGESTOR_V2_STATUS = 'SET_INGESTOR_V2_STATUS',
}

export type AdminPageAction =
    | {
          type: AdminPageActionType.GET_INGESTOR_STATUS;
      }
    | {
          type: AdminPageActionType.SET_INGESTOR_V2_STATUS;
          ingestorV2Status: IngestorV2State[];
      };
