import { RecordTypes } from '../../models';

export interface DetailFormData {
    type: 'form-data';
    label: string;
    apiFieldName: string;
    editable: boolean;
}

const formatLabel = (label: string) => {
    // If a property has any lowercase in it, split for display, otherwise just return it
    return label.match(/(?=[a-z])/) ? label.split(/(?=[A-Z])/).join(' ') : label;
};

const formData = (apiFieldName: string, editable: boolean): DetailFormData => ({
    type: 'form-data',
    label: formatLabel(apiFieldName),
    apiFieldName,
    editable,
});

/* BUILDING THE EDIT FORMS BY RECORD TYPE */

const COMMON_ADDRESS_EDIT_FIELDS = ['BillingFirstName', 'BillingLastName', 'BillingAddress1', 'BillingAddress2', 'BillingCity', 'BillingState', 'BillingZip', 'BillingCountry', 'BillingPhone', 'BillingAddressStandardizationCode', 'ShippingFirstName', 'ShippingLastName', 'ShippingAddress1', 'ShippingAddress2', 'ShippingCity', 'ShippingState', 'ShippingZip', 'ShippingCountry', 'ShippingPhone', 'ShippingAddressStandardizationCode'];
const COMMON_TO_ALL_EDIT_FIELDS = ['Email', 'ADID', 'PI'];
const SUBSCRIPTION_READ_ONLY_FORMS = ['Email', 'BillingFirstName', 'BillingLastName', 'BillingAddress1', 'BillingAddress2', 'BillingCity', 'BillingState', 'BillingZip', 'BillingCountry', 'BillingPhone', 'CustomerId', 'PendingPlanCode', 'PlanCode', 'Subsidiary', 'TrialEndsAt', 'BillDate', 'ActualPlanAmount', 'CouponCode', 'CancelAtNetSuite', 'CancelAtRecurly'];

export const EDIT_FORMS = {
    [RecordTypes.EmailOnly]: [...COMMON_TO_ALL_EDIT_FIELDS].map((field) => formData(field, true)),
    [RecordTypes.Lead]: [...COMMON_TO_ALL_EDIT_FIELDS, ...COMMON_ADDRESS_EDIT_FIELDS].map((field) => formData(field, true)),
    [RecordTypes.Quote]: [...COMMON_TO_ALL_EDIT_FIELDS, ...COMMON_ADDRESS_EDIT_FIELDS, 'OfferCode', 'ModeOfTransportation'].map((field) => formData(field, true)),
    [RecordTypes.Order]: [...COMMON_TO_ALL_EDIT_FIELDS, ...COMMON_ADDRESS_EDIT_FIELDS, 'OfferCode', 'ModeOfTransportation'].map((field) => formData(field, true)),
    [RecordTypes.OptOut]: ['Email', 'PI'].map((field) => formData(field, true)),
    [RecordTypes.Subscription]: [...SUBSCRIPTION_READ_ONLY_FORMS].map((field) => formData(field, true)),
    [RecordTypes.CancelOrder]: [],
    [RecordTypes.Membership]: [...COMMON_TO_ALL_EDIT_FIELDS, ...COMMON_ADDRESS_EDIT_FIELDS].map((field) => formData(field, true)),
};

/* BUILDING READ ONLY FORMS BY RECORD TYPE */

const COMMON_READ_ONLY_FORMS = ['BillingPhoneIsMobile', 'BillingPhoneIsMobileConsented', 'AxConsumerLink'].map((field) => formData(field, false));
export const READ_ONLY_FORMS = {
    [RecordTypes.EmailOnly]: [],
    [RecordTypes.Lead]: COMMON_READ_ONLY_FORMS,
    [RecordTypes.Quote]: COMMON_READ_ONLY_FORMS,
    [RecordTypes.Order]: COMMON_READ_ONLY_FORMS,
    [RecordTypes.OptOut]: [],
    [RecordTypes.Subscription]: [],
    [RecordTypes.CancelOrder]: [],
    [RecordTypes.Membership]: [],
};
