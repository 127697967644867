import moment from 'moment';

const DATE_FORMAT = 'MM/DD/YY HH:mm:ss';

export const formatDate = (
    raw: string | undefined,
    format?: string
): string => {
    if (!raw) {
        return '';
    }
    const dateObj = moment(raw);
    if (dateObj.isValid()) {
        return dateObj.format(format ?? DATE_FORMAT);
    }
    return '';
};
