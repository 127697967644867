import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

interface ButtonStyles {
    readonly disabled: boolean;
}

const Wrapper = styled.div<ButtonStyles>`
    align-items: center;
    background-color: var(${({ disabled }) => (!disabled ? '--rose-madder' : '--rose-madder-disabled')});
    border-radius: 15px;
    color: white;
    cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'auto')};
    display: grid;
    font-family: var(--open-sans-semibold-font);
    font-size: 16px;
    grid-auto-flow: column;
    grid-column-gap: 5px;
    height: 40px;
    padding: 0 35px;

    &:hover {
        background-color: var(${({ disabled }) => (disabled ? '--rose-madder-disabled' : '--rose-madder-hover')});
    }
`;

interface Props {
    disabled: boolean;
    isDiscardAll?: boolean;
    onClick: () => void;
}

const DiscardButton: React.FC<Props> = ({ disabled, isDiscardAll, onClick }) => {
    const handleClick = () => {
        if (disabled) {
            return;
        }
        onClick();
    };

    const discardText = isDiscardAll ? 'Discard Selected' : 'Discard';

    return (
        <Wrapper disabled={disabled} onClick={handleClick}>
            <FontAwesomeIcon icon={faTrashAlt} size="xs" />
            {discardText}
        </Wrapper>
    );
};

export default DiscardButton;
