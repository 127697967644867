import * as React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as Pages from './components/pages';
import { AppProvider } from './context';
import { CustomSnackbarProvider } from './components/common';
import { useReactOidc, withOidcSecure } from '@axa-fr/react-oidc-context';
import { adminSecure } from './components/auth/AdminSecure';
import RequestBuilder from './services/RequestBuilder';

const AppRoutes: React.FC = () => {
    const { oidcUser } = useReactOidc();
    // Once the user is logged in, set the token to be used for API calls
    if (oidcUser) {
        RequestBuilder.setToken(oidcUser.id_token);
    }
    return (
        <AppProvider>
            <CustomSnackbarProvider>
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/" component={withOidcSecure(Pages.DashboardPage)} />
                        <Route exact path="/records/:id" component={withOidcSecure(Pages.RecordDetailPage)} />
                        <Route exact path="/search" component={withOidcSecure(Pages.SearchPage)} />
                        <Route exact path="/search/:searchFor" component={withOidcSecure(Pages.SearchPage)} />
                        <Route exact path="/admin" component={withOidcSecure(adminSecure(Pages.AdminPage))}></Route>
                    </Switch>
                </BrowserRouter>
            </CustomSnackbarProvider>
        </AppProvider>
    );
};

export default AppRoutes;
