import * as React from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import { RecordStatus, RecordTypes } from '../../models';
import { useAppState } from '../../context';
import { globalFiltersInitialState, GlobalFiltersState } from '../../context/global-filter/State';
import { DateFilterMode } from '../../models/DateFilterMode';

const DismissIcon = styled.div`
    cursor: pointer;
    margin-right: 20px;
`;

// Display retry toast notification
export const useRetryToast = (records?: number) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const retryToast = (records?: number) => {
        const text = records > 1 ? `Success! ${records} records have been requeued for import.` : 'Success! The record has been requeued for import.';
        enqueueSnackbar(text, {
            variant: 'success',
            action: (key) => (
                <DismissIcon>
                    <FontAwesomeIcon icon={faTimes} size="sm" color="black" onClick={() => closeSnackbar(key)} />
                </DismissIcon>
            ),
        });
    };

    return { retryToast };
};

// Display discard toast notification
export const useDiscardedToast = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const discardedToast = () => {
        enqueueSnackbar('Success! The record has been discarded.', {
            variant: 'success',
            action: (key) => (
                <DismissIcon>
                    <FontAwesomeIcon icon={faTimes} size="sm" color="black" onClick={() => closeSnackbar(key)} />
                </DismissIcon>
            ),
        });
    };

    return { discardedToast };
};

// Display restart toast notification
export const useRestartedToast = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const restartedToast = () => {
        enqueueSnackbar('Success! The record has been restarted.', {
            variant: 'success',
            action: (key) => (
                <DismissIcon>
                    <FontAwesomeIcon icon={faTimes} size="sm" color="black" onClick={() => closeSnackbar(key)} />
                </DismissIcon>
            ),
        });
    };

    return { restartedToast };
};

// Parse filters from URL query string
export const useParseFilterQuery = () => {
    // Returns the global filter state (default OR populated with anything in the query string)
    const parseFilterQuery = (): GlobalFiltersState => {
        const globalFilters: GlobalFiltersState = { ...globalFiltersInitialState };
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('start') && searchParams.has('end')) {
            const startEpoch = Number.parseInt(searchParams.get('start') ?? '');
            const start = moment(startEpoch);
            const endEpoch = Number.parseInt(searchParams.get('end') ?? '');
            const end = moment(endEpoch);
            if (start.isValid() && end.isValid()) {
                globalFilters.currentFilters.dateRange.start = start;
                globalFilters.currentFilters.dateRange.end = end;
            }
        }
        if (searchParams.has('status')) {
            const allStatuses: RecordStatus[] = [];
            const statuses = searchParams.getAll('status');
            Object.entries(RecordStatus).forEach(([query, rs]) => {
                if (statuses.includes(query)) {
                    allStatuses.push(rs);
                }
            });
            globalFilters.currentFilters.recordStatus = [...allStatuses];
        }
        if (searchParams.has('type')) {
            const allTypes: RecordTypes[] = [];
            const types = searchParams.getAll('type');
            Object.entries(RecordTypes).forEach(([query, rt]) => {
                if (types.includes(query)) {
                    allTypes.push(rt);
                }
            });
            globalFilters.currentFilters.recordTypes = [...allTypes];
        }
        if (searchParams.has('dateFilterMode')) {
            const queryMode = searchParams.get('dateFilterMode');
            const mode = queryMode === DateFilterMode.LastTransition ? DateFilterMode.LastTransition : DateFilterMode.IngestDate;
            globalFilters.currentFilters.dateFilterMode = mode;
        }
        globalFilters.pendingFilters = { ...globalFilters.currentFilters };
        return globalFilters;
    };

    return { parseFilterQuery };
};

// Update query params based on filter state
export const useUpdateFilterQuery = () => {
    let {
        globalFilters: {
            currentFilters: { recordStatus, recordTypes, dateRange, dateFilterMode },
        },
    } = useAppState();

    const updateFilterQuery = () => {
        let searchParams = new URLSearchParams();
        if (dateRange.start.isValid()) {
            searchParams.set('start', `${dateRange.start.valueOf()}`);
        }
        if (dateRange.end.isValid()) {
            searchParams.set('end', `${dateRange.end.valueOf()}`);
        }
        if (recordStatus.length > 0) {
            const entries = Object.entries(RecordStatus).filter(([_, rs]) => recordStatus.includes(rs));
            entries.forEach(([queryStatus, _]) => {
                searchParams.append('status', queryStatus);
            });
        }
        if (recordTypes.length > 0) {
            const entries = Object.entries(RecordTypes).filter(([_, rt]) => recordTypes.includes(rt));
            entries.forEach(([queryType, _]) => {
                searchParams.append('type', queryType);
            });
        }
        if (dateFilterMode) {
            searchParams.append('dateFilterMode', dateFilterMode);
        }
        window.history.replaceState('', '', `${window.location.origin}${window.location.pathname}?${searchParams}`);
    };

    return { updateFilterQuery };
};
