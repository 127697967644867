import { BipsliDocument } from '../../models';

export enum RecordDetailPageActionType {
    SET_RECORD = 'SET_RECORD',
    SET_EDIT_RECORD = 'SET_EDIT_RECORD',
    REVERT_FIELD = 'REVERT_FIELD',
    TOGGLE_ORIG_RECORD = 'TOGGLE_ORIG_RECORD',
    TOGGLE_EDITING_FIELD = 'TOGGLE_EDITING_FIELD',
    CLEAR_EDITING_FIELDS = 'CLEAR_EDITING_FIELDS',
    CLEAR_RECORDS = 'CLEAR_RECORDS',
}

export type RecordDetailPageAction =
    | {
          type: RecordDetailPageActionType.SET_RECORD;
          record: BipsliDocument;
      }
    | {
          type: RecordDetailPageActionType.SET_EDIT_RECORD;
          record: Partial<BipsliDocument>;
      }
    | {
          type: RecordDetailPageActionType.REVERT_FIELD;
          field: keyof BipsliDocument;
      }
    | { type: RecordDetailPageActionType.TOGGLE_ORIG_RECORD }
    | { type: RecordDetailPageActionType.CLEAR_EDITING_FIELDS }
    | {
          type: RecordDetailPageActionType.TOGGLE_EDITING_FIELD;
          field: keyof BipsliDocument;
      }
    | { type: RecordDetailPageActionType.CLEAR_RECORDS };
