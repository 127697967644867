import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleUp, faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons';

import { useAppState } from '../../context';
interface StatusDot {
    active: boolean;
}
const Widget = styled.div`
    background-color: white;
    border-radius: 10px;
    box-sizing: border-box;
    color: var(--eerie-black);
    font-size: 11px;
`;

const LiveDot = styled.div`
    background-color: var(--rose-madder);
    border-radius: 50%;
    height: 8px;
    width: 8px;
`;

const StatusDot = styled.div<StatusDot>`
    background-color: var(${({ active }) => (active ? '--sheen-green' : '--sortof-gray')});
    border-radius: 50%;
    height: 8px;
    width: 8px;
`;

const Live = styled.div`
    color: var(--eerie-black);
    font-family: var(--open-sans-bold-font);
    font-size: 15px;
    letter-spacing: 1.5px;
`;

const TitleRow = styled.div`
    align-items: center;
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 5px;
    height: 35px;
    justify-content: start;
    padding-left: 13px;
`;

const Table = styled.div`
    background-color: var(--platinum);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 1px solid var(--platinum);
    display: grid;
    grid-gap: 1px;
    grid-template-columns: repeat(6, 1fr);
    overflow: hidden;
`;

const HeaderBorder = styled.div`
    background-color: var(--french-blue);
    grid-column: 1 / -1;
    height: 1px;
`;

const TableCell = styled.div`
    align-items: center;
    background-color: white;
    display: flex;
    height: 27.5px;
    padding: 0 11px;
    white-space: nowrap;
`;

const DoubleCell = styled(TableCell)`
    display: grid;
    grid-template-columns: 14px 1fr;
`;

const convertToMins = (numSeconds: number) => {
    return Number(numSeconds / 60).toFixed(2);
};

const LiveWidget: React.FC = () => {
    const {
        dashboardPage: { liveStats },
        adminPage: { ingestorV2Status },
    } = useAppState();

    const renderDelta = (l?: number, r?: number) => {
        if (l === undefined || r === undefined) {
            return null;
        }
        const icon = l < r ? faArrowAltCircleDown : faArrowAltCircleUp;
        const color = l < r ? '--sheen-green' : '--rose-madder';
        return <FontAwesomeIcon icon={icon} color={`var(${color})`} size="sm" style={{ marginRight: '5px' }} />;
    };

    return (
        <Widget>
            <TitleRow>
                <LiveDot /> <Live>LIVE</Live> Processing Speed
            </TitleRow>
            <Table>
                <TableCell>Record Type</TableCell>
                <TableCell>Processing</TableCell>
                <TableCell>Errored</TableCell>
                <TableCell title="Docs per minute for last 5 minutes">DPM</TableCell>
                <TableCell title="Average completion time per document">4h AVG</TableCell>
                <TableCell title="Average completion time per document">30d AVG</TableCell>
                <HeaderBorder />
                {liveStats.map((stat) => {
                    let status = stat.recordType && (ingestorV2Status.find((i) => i.OriginalStatus.enabled && i.OriginalStatus.recordType.toLowerCase() === stat.recordType.toLowerCase()) || ingestorV2Status.find((i) => i.OriginalStatus.enabled && i.OriginalStatus.recordType.toLowerCase() === stat.recordType.toLowerCase()));
                    let active = false;
                    if (status) {
                        active = status.OriginalStatus.enabled;
                    }
                    return (
                        <React.Fragment key={stat.recordType}>
                            <DoubleCell>
                                <StatusDot active={active} /> {stat.recordType}
                            </DoubleCell>
                            <TableCell>{stat.recordsInProcess}</TableCell>
                            <TableCell>{stat.recordsInError}</TableCell>
                            <TableCell title="Docs per minute for last 5 minutes">{stat.docsPerMinute === null ? 0 : stat.docsPerMinute}</TableCell>
                            <TableCell title="Average completion time per document">
                                {renderDelta(stat.recentAvgProcessTime, stat.thirtyDayAvgProcessTime)}
                                {stat.recentAvgProcessTime === undefined ? '-' : `${convertToMins(stat.recentAvgProcessTime)}min`}
                            </TableCell>
                            <TableCell title="Average completion time per document">{stat.thirtyDayAvgProcessTime === undefined ? '-' : `${convertToMins(stat.thirtyDayAvgProcessTime)}min`}</TableCell>
                        </React.Fragment>
                    );
                })}
            </Table>
        </Widget>
    );
};

export default LiveWidget;
