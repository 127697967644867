import { ModalType } from '../../models';

export interface ModalState {
    confirmed: boolean;
    confirmedAll: boolean;
    cancelledAll: boolean;
    recordId: string;
    recordType: string;
    recordStatus: string;
    recordsLength: number;
    type: ModalType;
    visible: boolean;
    data: any;
    onConfirm(): any;
}

export const initialState: ModalState = {
    confirmed: false,
    confirmedAll: false,
    cancelledAll: false,
    recordId: '',
    recordType: '',
    recordStatus: '',
    recordsLength: 0,
    type: ModalType.DISCARD,
    visible: false,
    data: null,
    onConfirm: null,
};
