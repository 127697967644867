import { ModalState as State } from './State';
import { ModalAction as Action, ModalActionType as ActionType } from './Action';

const checkExhaustive = (_: never): void => {};

export const modalReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionType.CLOSE_MODAL: {
            return {
                ...state,
                visible: false,
                data: null,
            };
        }
        case ActionType.SHOW_DISCARD_MODAL: {
            return {
                ...state,
                recordId: action.recordId,
                recordType: action.recordType,
                recordStatus: action.recordStatus,
                recordsLength: action.recordsLength,
                type: action.modalType,
                visible: true,
            };
        }
        case ActionType.SHOW_RESTART_MODAL: {
            return {
                ...state,
                recordId: action.recordId,
                recordType: action.recordType,
                recordStatus: action.recordStatus,
                type: action.modalType,
                visible: true,
            };
        }
        case ActionType.SHOW_ERROR_MODAL: {
            return {
                ...state,
                recordId: null,
                recordType: null,
                recordStatus: null,
                data: action.data,
                type: action.modalType,
                visible: true,
            };
        }
        case ActionType.SHOW_ERROR_DETAIL_MODAL: {
            return {
                ...state,
                recordId: null,
                recordType: null,
                recordStatus: null,
                data: action.data,
                type: action.modalType,
                visible: true,
            };
        }
        case ActionType.TOGGLE_CONFIRM_DISCARD: {
            return {
                ...state,
                confirmed: !state.confirmed,
            };
        }
        case ActionType.TOGGLE_CONFIRM_RESTART: {
            return {
                ...state,
                confirmed: !state.confirmed,
            };
        }
        case ActionType.TOGGLE_CONFIRM_DISCARD_ALL: {
            return {
                ...state,
                confirmedAll: !state.confirmedAll,
            };
        }
        case ActionType.TOGGLE_CANCELED_DISCARD_ALL: {
            return {
                ...state,
                cancelledAll: !state.cancelledAll,
            };
        }
        default:
            checkExhaustive(action);
            return state;
    }
};
