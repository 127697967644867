import * as React from 'react';
import styled from 'styled-components';
import { ButtonType } from '../../models/ButtonTypes';
interface ButtonProps {
    disabled: boolean;
    fontSize: string;
}
const ButtonBase = styled.div<ButtonProps>`
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    color: white;
    cursor: pointer;
    display: grid;
    font-family: var(--open-sans-semibold-font);
    font-size: ${({ fontSize }) => fontSize};
    grid-auto-flow: column;
    grid-column-gap: 5px;
    padding: 0.25em 1em;
`;
const BlueButton = styled(ButtonBase)<ButtonProps>`
    background-color: var(${({ disabled }) => (!disabled ? '--french-blue' : '--french-blue-disabled')});

    &:hover {
        background-color: var(${({ disabled }) => (disabled ? '--french-blue-disabled' : '--french-blue-hover')});
    }
`;

const RedButton = styled(ButtonBase)<ButtonProps>`
    background-color: var(${({ disabled }) => (!disabled ? '--rose-madder' : '--rose-madder-disabled')});

    &:hover {
        background-color: var(${({ disabled }) => (disabled ? '--rose-madder-disabled' : '--rose-madder-hover')});
    }
`;

interface Props {
    type?: ButtonType;
    disabled?: boolean;
    fontSize?: string;
    label: string;
    onClick: () => void;
}

export const BaseButton: React.FC<Props> = ({ type = ButtonType.PrimaryBlue, disabled = false, fontSize = '16px', label, onClick }) => {
    return type === ButtonType.PrimaryBlue ? (
        <BlueButton fontSize={fontSize} disabled={disabled} onClick={onClick}>
            {label}
        </BlueButton>
    ) : (
        <RedButton fontSize={fontSize} disabled={disabled} onClick={onClick}>
            {label}
        </RedButton>
    );
};
