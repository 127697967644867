import env from '../env.json';

interface Environment {
    baseUrl: String;
    authority: string;
    clientId: string;
    envName: string;
    adminGroupId: string;
    readGroupId: string;
    writeGroupId: string;
    devGroupId: string;
}

export const environment: Environment = {
    baseUrl: env?.baseUrl ?? 'https://goktr2hwwj.execute-api.us-west-2.amazonaws.com/v1',
    authority: env?.authority ?? 'https://cognito-idp.us-west-2.amazonaws.com/us-west-2_8ENG5t2lj',
    clientId: env?.clientId ?? 'v346a3i4ga64e6jjoocmv000f',
    envName: env?.envName ?? 'develop',
    adminGroupId: env?.adminGroupId ?? '2d63888d-1f45-4674-84d3-78eaf9347cce',
    readGroupId: env?.readGroupId ?? 'd530831f-52a6-4454-9ad4-7684d7c6ee9d',
    writeGroupId: env?.writeGroupId ?? '86e469fe-f507-471f-ac7b-477890500998',
    devGroupId: env?.devGroupId ?? '72d2bbf3-5dc0-4c10-9e50-dd30da883592',
};
