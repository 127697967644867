import * as React from 'react';
import styled from 'styled-components';

const Banner = styled.div`
    align-items: center;
    background-color: var(--international-orange);
    color: white;
    display: flex;
    font-family: var(--open-sans-semibold-font);
    font-size: 20px;
    height: 60px;
    justify-content: center;
    width: 100%;
`;

const EditedBanner: React.FC = () => {
    return (
        <Banner>
            This record has been edited. Click retry to queue the record for
            import.
        </Banner>
    );
};

export default EditedBanner;
