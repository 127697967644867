import * as React from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';

import { FiltersButton } from '.';
import SearchBar from './SearchBar';
import { useAppDispatch, toggleFlyoutOpen } from '../../context';

const Wrapper = styled.div`
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 77px minmax(auto, 670px);
    padding-right: 20px;
`;

export const FilterSearch: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const openFlyout = () => {
        dispatch(toggleFlyoutOpen());
    };
    const onSearch = (searchFor: string) => {
        history.push({
            pathname: `/search/${searchFor}`,
            search: location.search,
        });
    };

    return (
        <Wrapper>
            <FiltersButton isOpen={false} onClick={openFlyout} />
            <SearchBar onEnter={onSearch} />
        </Wrapper>
    );
};
