import * as React from 'react';
import styled from 'styled-components';

import { revertRecordDetailField, setEditedRecord, showErrorModal, toggleEditingField, useAppDispatch, useAppState } from '../../context';
import { RecordsSvc } from '../../services';
import { BipsliDocument } from '../../models';
import { RecordDetailRequest } from '../../models/request';
import EditField from './EditField';
import { useFetchRecordDetail } from './Hooks';
import { DetailFormData, EDIT_FORMS, READ_ONLY_FORMS } from './EditFormData';
import { useReactOidc } from '@axa-fr/react-oidc-context';

const DetailLabel = styled.div`
    color: var(--eerie-black);
    font-family: var(--open-sans-bold-font);
    font-size: 13px;
    margin-bottom: 3px;
`;

const ReadOnly = styled.div`
    align-items: center;
    color: var(--eerie-black);
    display: flex;
    font-size: 13px;
    height: 40px;
`;

interface DetailEntry {
    key: keyof BipsliDocument;
    value: string;
    formData: DetailFormData;
}

interface Props {
    readOnly: boolean;
    document: BipsliDocument;
}

const DetailFormFields: React.FC<Props> = ({ readOnly, document }) => {
    const dispatch = useAppDispatch();
    const { oidcUser } = useReactOidc();
    const { profile } = oidcUser;
    const {
        recordDetailPage: { editedRecord, editingFields },
    } = useAppState();
    const { fetchRecordDetail } = useFetchRecordDetail();

    const onChangeField = (key: keyof BipsliDocument, value: string) => {
        dispatch(
            setEditedRecord({
                [key]: value,
            })
        );
    };

    if (!document.RecordType) {
        return <></>;
    }

    const onSave = async (documentId: string, field: string, oldValue: string, newValue: string, dynamoType: string = 'S') => {
        if (typeof oldValue === 'number') {
            dynamoType = 'N';
        }
        // TODO get username from auth data
        const request: RecordDetailRequest = {
            field,
            oldValue: `${oldValue}`,
            newValue,
            user: profile.email,
            dynamoType,
        };
        await RecordsSvc.putRecordDetail(documentId, request).catch((e) => {
            dispatch(showErrorModal());
        });
        // fetch updated record
        fetchRecordDetail(documentId, editedRecord);
    };

    const onRevert = (field: keyof BipsliDocument) => {
        dispatch(revertRecordDetailField(field));
    };

    const onToggleEditingField = (field: keyof BipsliDocument) => {
        dispatch(toggleEditingField(field));
    };

    // render a single form field
    const renderEditField = ({ key, value, formData }: DetailEntry) => {
        return (
            <div key={key}>
                <DetailLabel>{formData.label}</DetailLabel>
                {!readOnly && formData.editable ? <EditField value={value} onChange={(value: string) => onChangeField(key, value)} onSave={() => onSave(document.Id, formData.apiFieldName, document[key] as string, value)} onRevert={() => onRevert(key)} isEditMode={editingFields.includes(key)} onToggleEditMode={() => onToggleEditingField(key)} /> : <ReadOnly>{value !== undefined && value !== null && value.toString()}</ReadOnly>}
            </div>
        );
    };

    const renderEditFields = () => {
        const record = readOnly ? document : editedRecord;
        const editFields = EDIT_FORMS[record.RecordType] || [];
        const readOnlyFields = READ_ONLY_FORMS[record.RecordType] || [];
        return editFields.concat(readOnlyFields).map((formData) => {
            const key = formData.apiFieldName as keyof BipsliDocument;
            const value = record[key] as string;
            return renderEditField({ key, value, formData });
        });
    };

    return <>{renderEditFields()}</>;
};

export default DetailFormFields;
