import * as React from 'react';
import styled from 'styled-components';

import { FilterBadges, FilterSearch, Header, ModalShell } from '../common';
import Widgets from '../dashboard/TotalWidgets';
import DashboardTabs from '../dashboard/DashboardTabs';
import FilterFlyout from '../filter-flyout/FilterFlyout';
import { useFetchErrorRecords } from '../dashboard/Hooks';
import { useUpdateFilterQuery } from '../common/Hooks';
import { useDiscardedToast } from '../common/Hooks';
import { disableSpinner, enableSpinner, removeErrorRecord, resetFiltersToDefault, setFiltersAreApplied, setLiveStats, showErrorModal } from '../../context';
import { toggleConfirmDiscard, useAppDispatch, useAppState } from '../../context';
import { RecordsSvc } from '../../services';
import { mapLiveStatResponse } from '../../models';
import { FilterReset } from '../common/FilterReset';
import { FullPageSpinner } from '../common/FullPageSpinner';
import { useFetchIngestorStatus } from '../admin-page/Hooks';
import { createBrowserHistory } from 'history';
import { initialState } from '../../context/AppState';

const Content = styled.div`
    display: grid;
    grid-gap: 20px;
    padding: 20px 40px;
`;

const FilterSearchWrapper = styled.div`
    margin-top: 15px;
`;

const BadgesRow = styled.div`
    width: calc(100vw - 80px);
    display: grid;
    grid-template-columns: 8fr 1fr;
`;

let liveStatsInterval = 0;

const DashboardPage: React.FC = () => {
    const { fetchErrorRecords } = useFetchErrorRecords();
    let {
        globalFilters: { currentFilters, customFiltersAreApplied },
        modal: { confirmed, recordId, recordType, recordStatus },
    } = useAppState();
    const dispatch = useAppDispatch();
    const { updateFilterQuery } = useUpdateFilterQuery();
    const { discardedToast } = useDiscardedToast();
    const { fetchIngestorStatus } = useFetchIngestorStatus();

    const history = createBrowserHistory();

    //Reset the currentFilters from History
    React.useEffect(() => {
        if (!customFiltersAreApplied && history.location.pathname.includes('records/')) {
            // eslint-disable-next-line
            currentFilters = initialState.globalFilters.currentFilters;
        }
    }, [customFiltersAreApplied]);

    // [When global filters change] Update URL search params from global filters
    React.useEffect(updateFilterQuery, [currentFilters]);

    // [When global filters change] Fetch records using the global filters
    React.useEffect(fetchErrorRecords, [currentFilters]);

    React.useEffect(() => {
        // Get the current status of the ingestors
        fetchIngestorStatus();

        // Setup the long-polling for fetching status every 10s
        const getStatsAsync = async () => {
            const resp = await RecordsSvc.getLiveStats().catch((e) => {
                console.error('Error trying to get live stats', e);
            });
            if (resp) {
                const stats = resp.map(mapLiveStatResponse);
                dispatch(setLiveStats(stats));
            }
        };

        liveStatsInterval = setInterval(getStatsAsync, 10000);
        getStatsAsync();
        return () => {
            clearInterval(liveStatsInterval);
        };
        // eslint-disable-next-line
    }, [dispatch]);

    React.useEffect(() => {
        if (!confirmed) {
            return;
        }

        const postDiscard = async () => {
            dispatch(enableSpinner());
            const result = await RecordsSvc.putDiscard(recordId, recordType, recordStatus).catch((e) => console.error('Error trying to discard record', e));
            if (result) {
                dispatch(removeErrorRecord(recordId));
                discardedToast();
            } else {
                dispatch(showErrorModal());
            }
            // Reset confirm state
            dispatch(toggleConfirmDiscard());
            dispatch(disableSpinner());
        };

        postDiscard();
        // eslint-disable-next-line
    }, [confirmed, recordId, dispatch]);

    const onResetClick = () => {
        dispatch(resetFiltersToDefault());
        dispatch(setFiltersAreApplied(true));
    };

    return (
        <div>
            <FullPageSpinner />
            <Header />
            <FilterSearchWrapper>
                <FilterSearch />
            </FilterSearchWrapper>
            <Content>
                <BadgesRow>
                    <FilterBadges />
                    <FilterReset onClick={onResetClick} />
                </BadgesRow>
                <Widgets />
                <DashboardTabs />
            </Content>
            <FilterFlyout />
            <ModalShell />
        </div>
    );
};

export default DashboardPage;
