import moment, { Moment } from 'moment';

import { RecordStatus, RecordTypes } from '../../models';
import { DateFilterMode } from '../../models/DateFilterMode';
export interface FiltersState {
    recordStatus: RecordStatus[];
    recordTypes: RecordTypes[];
    dateRange: {
        start: Moment;
        end: Moment;
    };
    dateFilterMode: DateFilterMode;
}
export interface GlobalFiltersState {
    currentFilters: FiltersState;
    pendingFilters?: FiltersState;
    filtersAreChangedButUnapplied: boolean;
    customFiltersAreApplied: boolean;
    dateRangeGreaterThanThirtyDays: boolean;
    filtersAreApplied?: boolean;
}

export const globalFiltersInitialState: GlobalFiltersState = {
    currentFilters: {
        recordStatus: [],
        recordTypes: [],
        dateRange: {
            start: moment().startOf('day').subtract(7, 'days'),
            end: moment().endOf('day'),
        },
        dateFilterMode: DateFilterMode.IngestDate,
    },
    pendingFilters: {
        recordStatus: [],
        recordTypes: [],
        dateRange: {
            start: moment().startOf('day').subtract(7, 'days'),
            end: moment().endOf('day'),
        },
        dateFilterMode: DateFilterMode.IngestDate,
    },
    filtersAreChangedButUnapplied: false,
    customFiltersAreApplied: false,
    dateRangeGreaterThanThirtyDays: false,
    filtersAreApplied: false,
};
