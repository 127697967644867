import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

interface SelectStyles {
    readonly isOpen: boolean;
}

const Wrapper = styled.div`
    position: relative;
    width: 120px;
`;

const StyledSelect = styled.div<SelectStyles>`
    align-items: center;
    appearance: none;
    border-radius: 8px;
    border: 1px solid var(--platinum);
    box-sizing: border-box;
    color: var(--eerie-black);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    visibility: ${({ isOpen }) => (isOpen ? 'hidden' : 'visible')};
    font-size: 13px;
    outline: none;
    padding: 10px 15px;
    width: 100%;
`;

const StyledOptions = styled.div<SelectStyles>`
    background-color: white;
    border-radius: 8px;
    border: 1px solid var(--platinum);
    box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
    box-sizing: border-box;
    display: ${({ isOpen }) => (isOpen ? 'grid' : 'none')};
    height: 240px;
    left: 0;
    overflow-y: auto;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5;
`;

const StyledOption = styled.div`
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    box-sizing: border-box;
    color: var(--eerie-black);
    cursor: pointer;
    font-size: 13px;
    height: 40px;
    padding: 10px 15px;

    &:hover {
        background-color: var(--cultured);
        border-bottom: 1px solid var(--platinum);
        border-top: 1px solid var(--platinum);
    }
`;

interface Option {
    label: string;
    value: number;
}

const option = (label: string, value: number): Option => ({
    label,
    value,
});

const OPTIONS: Option[] = [
    option('12:00 AM', 0),
    option('1:00 AM', 1),
    option('2:00 AM', 2),
    option('3:00 AM', 3),
    option('4:00 AM', 4),
    option('5:00 AM', 5),
    option('6:00 AM', 6),
    option('7:00 AM', 7),
    option('8:00 AM', 8),
    option('9:00 AM', 9),
    option('10:00 AM', 10),
    option('11:00 AM', 11),
    option('12:00 PM', 12),
    option('1:00 PM', 13),
    option('2:00 PM', 14),
    option('3:00 PM', 15),
    option('4:00 PM', 16),
    option('5:00 PM', 17),
    option('6:00 PM', 18),
    option('7:00 PM', 19),
    option('8:00 PM', 20),
    option('9:00 PM', 21),
    option('10:00 PM', 22),
    option('11:00 PM', 23),
];

interface Props {
    value: number;
    onChange: (value: number) => void;
}

export const TimePicker: React.FC<Props> = ({ value, onChange }) => {
    const [isOpen, setOpen] = React.useState(false);

    const toggleIsOpen = () => {
        setOpen((prevState) => !prevState);
    };

    const renderOption = ({ label, value }: Option) => {
        return (
            <StyledOption
                key={value}
                onClick={() => {
                    onChange(value);
                    toggleIsOpen();
                }}
            >
                {label}
            </StyledOption>
        );
    };

    const selectedOption = OPTIONS.find((opt) => opt.value === value);

    return (
        <Wrapper>
            <StyledSelect onClick={toggleIsOpen} isOpen={isOpen}>
                {selectedOption?.label}
                <FontAwesomeIcon icon={faClock} size="sm" />
            </StyledSelect>
            <StyledOptions isOpen={isOpen}>
                {OPTIONS.map(renderOption)}
            </StyledOptions>
        </Wrapper>
    );
};
