import * as React from 'react';
import styled from 'styled-components';

import TotalWidget from './TotalWidget';
import LiveWidget from './LiveWidget';
import { useAppState } from '../../context';

const Wrapper = styled.div`
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: repeat(3, 1fr) 1px 1fr;
    justify-content: start;
`;

const Divider = styled.div`
    background-color: var(--french-blue);
`;

const TotalWidgets: React.FC = () => {
    const {
        dashboardPage: { totals, averages },
    } = useAppState();

    const allTotals = [
        { label: 'Total Records Ingested', total: totals.ingested, currentAverage: averages.currentIngested, previousAverage: averages.previousIngested, desiredOutcome: true },
        { label: 'Records Succeeded', total: totals.succeeded, currentAverage: averages.currentSucceeded, previousAverage: averages.previousSucceeded, desiredOutcome: true },
        { label: 'Records Errored', total: totals.errored, currentAverage: averages.currentErrored, previousAverage: averages.previousErrored, desiredOutcome: false },
    ];

    return (
        <Wrapper>
            {allTotals.map(({ label, total, currentAverage, previousAverage, desiredOutcome }) => {
                return <TotalWidget key={label} label={label} total={total} currentAverage={currentAverage} previousAverage={previousAverage} desiredOutcome={desiredOutcome} displayPerformance={averages.displayPerformance} />;
            })}
            <Divider />
            <LiveWidget />
        </Wrapper>
    );
};

export default TotalWidgets;
