import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Wrapper = styled.div`
    align-items: center;
    display: flex;
    position: relative;
`;

const SearchInput = styled.input`
    background-color: white;
    border-radius: 20px;
    border: 0;
    box-sizing: border-box;
    color: var(--eerie-black);
    font-size: 13px;
    height: 45px;
    outline: none;
    padding-left: 25px;
    padding-right: 62px;
    width: 100%;
`;

const IconWrapper = styled.div`
    cursor: pointer;
    position: absolute;
    right: 25px;
`;

interface Props {
    onEnter: (value: string) => void;
}

const SearchBar: React.FC<Props> = ({ onEnter }) => {
    const inputRef = React.useRef<HTMLInputElement>(null);

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            onEnter(e.currentTarget.value);
        }
    };
    const handleIconClick = () => {
        if (inputRef.current) {
            onEnter(inputRef.current.value);
        }
    };

    return (
        <Wrapper>
            <SearchInput
                type="text"
                placeholder="Search"
                onKeyPress={handleKeyPress}
                ref={inputRef}
            />
            <IconWrapper>
                <FontAwesomeIcon
                    icon={faSearch}
                    size="1x"
                    onClick={handleIconClick}
                />
            </IconWrapper>
        </Wrapper>
    );
};

export default SearchBar;
