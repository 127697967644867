import * as R from 'ramda';

import { RecordsSvc } from '../../services';
import { disableSpinner, enableSpinner, setAverages, setErrorRecords, setTotals, showErrorModal, useAppDispatch, useAppState } from '../../context';
import { ColumnSort, FilterQueryParams, RecordErrorsTableColumn } from '../../models';
import { SearchResultDoc } from '../../models/response';

const buildSortDirection = (orderBy: ColumnSort) => {
    return orderBy === ColumnSort.DESC ? R.descend : R.ascend;
};

export const useSortedRecordErrors = (): { sortedRecords: SearchResultDoc[] } => {
    const {
        dashboardPage: { sorting, recordErrors },
    } = useAppState();
    let sortedRecords = [...recordErrors];
    if (recordErrors.length <= 1 || sorting.orderBy === ColumnSort.NONE) {
        return { sortedRecords };
    }
    const sortDirection = buildSortDirection(sorting.orderBy);

    switch (sorting.sortBy) {
        case RecordErrorsTableColumn.ID: {
            const byId: (re: SearchResultDoc) => string = R.prop('documentId');
            sortedRecords = R.sort(sortDirection(byId), sortedRecords);
            break;
        }
        case RecordErrorsTableColumn.RECORD_TYPE: {
            const byRecordType: (re: SearchResultDoc) => string = R.prop('recordType');
            sortedRecords = R.sort(sortDirection(byRecordType), sortedRecords);
            break;
        }
        case RecordErrorsTableColumn.STEP: {
            const byStep: (re: SearchResultDoc) => string = R.prop('nextStep');
            sortedRecords = R.sort(sortDirection(byStep), sortedRecords);
            break;
        }
        case RecordErrorsTableColumn.CATEGORY: {
            const byCateory: (re: SearchResultDoc) => string = R.prop('errorCategory');
            sortedRecords = R.sort(sortDirection(byCateory), sortedRecords);
            break;
        }
        case RecordErrorsTableColumn.SERVICE: {
            const byService: (re: SearchResultDoc) => string = R.prop('errorService');
            sortedRecords = R.sort(sortDirection(byService), sortedRecords);
            break;
        }
        case RecordErrorsTableColumn.NAME: {
            const byName: (re: SearchResultDoc) => string = R.prop('errorName');
            sortedRecords = R.sort(sortDirection(byName), sortedRecords);
            break;
        }
        case RecordErrorsTableColumn.MESSAGE: {
            const byErrorMsg: (re: SearchResultDoc) => string = R.prop('errorMessage');
            sortedRecords = R.sort(sortDirection(byErrorMsg), sortedRecords);
            break;
        }
    }

    return { sortedRecords };
};

export const useFetchErrorRecords = () => {
    const dispatch = useAppDispatch();
    const {
        globalFilters: {
            currentFilters: { recordStatus, recordTypes, dateRange, dateFilterMode },
        },
    } = useAppState();

    const fetchErrorRecords = () => {
        const params: FilterQueryParams = {
            start: dateRange.start,
            end: dateRange.end,
            recordTypes: recordTypes,
            statuses: recordStatus,
            dateFilterMode: dateFilterMode,
        };
        const getRecordsAsync = async () => {
            dispatch(enableSpinner());
            const data = await RecordsSvc.getDashboardData(params).catch((e) => {
                console.error('Error fetching dashboard data', e);
                dispatch(showErrorModal(e));
            });
            if (data) {
                if (data.title === 'Not Found' && data.status === 404) {
                    dispatch(showErrorModal('No records found for the selected filters.'));
                    dispatch(setTotals(0, 0, 0));
                    dispatch(setErrorRecords([]));
                } else {
                    const hidePerformance = params.statuses && params.statuses.length > 0;
                    dispatch(setTotals(data.totalIngested, data.totalSuccess, data.totalErrors));
                    dispatch(setAverages(data.dailyPerformance.currentDailyIngested, data.dailyPerformance.currentDailySuccess, data.dailyPerformance.currentDailyErrors, data.dailyPerformance.previousDailyIngested, data.dailyPerformance.previousDailySuccess, data.dailyPerformance.previousDailyErrors, !hidePerformance));
                    dispatch(setErrorRecords(data.errorRecords.items));
                }
            }
            dispatch(disableSpinner());
        };
        getRecordsAsync();
    };

    return {
        fetchErrorRecords,
    };
};
