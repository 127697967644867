import { DashboardPageActionType } from './Action';
import { AppAction } from '../AppAction';
import { DashboardTabs, LiveStat, RecordErrorsTableColumn } from '../../models';
import { SearchResultDoc } from '../../models/response';

export const selectDashboardTab = (tab: DashboardTabs): AppAction => ({
    type: DashboardPageActionType.SELECT_DB_TAB,
    tab,
});

export const toggleFlyoutOpen = (): AppAction => ({
    type: DashboardPageActionType.TOGGLE_FLYOUT_OPEN,
});

export const sortRecordErrors = (sortBy: RecordErrorsTableColumn): AppAction => ({
    type: DashboardPageActionType.RECORD_ERRORS_SORT,
    sortBy,
});

export const setErrorRecords = (records: SearchResultDoc[]): AppAction => ({
    type: DashboardPageActionType.SET_ERROR_RECORDS,
    records,
});

export const setTotals = (ingested: number, succeeded: number, errored: number): AppAction => ({
    type: DashboardPageActionType.SET_TOTALS,
    ingested,
    succeeded,
    errored,
});

export const setAverages = (currentIngested: number, currentSucceeded: number, currentErrored: number, previousIngested: number, previousSucceeded: number, previousErrored: number, displayPerformance: boolean): AppAction => ({
    type: DashboardPageActionType.SET_AVERAGES,
    currentIngested,
    currentSucceeded,
    currentErrored,
    previousIngested,
    previousSucceeded,
    previousErrored,
    displayPerformance,
});

export const setLiveStats = (liveStats: LiveStat[]): AppAction => ({
    type: DashboardPageActionType.SET_LIVE_STATS,
    liveStats,
});

export const removeErrorRecord = (recordId: string): AppAction => ({
    type: DashboardPageActionType.REMOVE_ERROR,
    recordId,
});
